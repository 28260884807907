// MainTopComponent.jsx
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import getColor from "../VariableColors/GetColor";
import "../MainComponent/MainComponent.scss";
import { useArtContext } from "../../../../../components/context/ArtikullContextPosBar";

const MainTopComponent = ({ kls01, artForGroups, isMainTopOpen }) => {
  const { setArtData } = useArtContext();
  //On Click Ca Perfshijne grupet (ca artikujsh)
  const kategoriBtnfunction = async (kodi) => {
    try {
      const dataFilter = artForGroups.filter(
        (item) => item.Art_Kls01_Kodi === kodi
      );
      setArtData(dataFilter);
    } catch (error) {
      console.error("kategori function error :", error);
    }
  };

  const ColoredItem = styled(Paper)(({ theme, pershkrimcolor }) => ({
    position: "relative",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: getColor(theme, pershkrimcolor),
    height: "70px",
    cursor: "pointer",
  }));

  const ColorIndicator = styled("div")(({ theme, pershkrimcolor }) => ({
    position: "absolute",
    top: 0,
    left: "1%",
    width: "2%",
    height: "100%",
    borderRadius: "10%",
    overflow: "hidden",
    backgroundColor: getColor(theme, pershkrimcolor),
    "&::before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
    },
  }));

  function FormRow({ data, theme }) {
    return (
      <React.Fragment>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.Kodi}>
            <ColoredItem
              theme={theme}
              pershkrimcolor={item.Pershkrim}
              onClick={() => kategoriBtnfunction(item.Kodi, item)}
            >
              <ColorIndicator theme={theme} pershkrimcolor={item.Pershkrim} />
              {item.Pershkrim}
            </ColoredItem>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  const theme = {};

  return (
    <div
      className="mainTop"
      style={{
        // overflow: "hidden",
        height: isMainTopOpen ? "25%" : "0%",
        transition: "height 1.2s ease",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {Array.from({ length: Math.ceil(kls01.length / 4) }).map(
            (_, rowIndex) => (
              <Grid container item spacing={3} key={rowIndex}>
                <FormRow
                  theme={theme}
                  data={kls01.slice(rowIndex * 4, rowIndex * 4 + 4)}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default MainTopComponent;
