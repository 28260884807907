import React from "react";
import "./print80Skontrino.scss";

function SkontrinoUrdhriPunes({ state, konfigurimeRedux }) {
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";
    return dateTimeString.replace("T", " ");
  };
  // Safely access the first element of konfigurimeRedux
  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  // Handle the case where ndermarrje might be null or undefined
  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }
  return (
    <table className="hide-border">
      <thead>
        <tr className="flex " style={{ flexDirection: "column" }}>
          <td className="title1_urdhri" colSpan={4}>
            Fature Porosi
          </td>
          {/* <td className="tds-footer" colSpan={4}>
            {ndermarrje.Pershkrim}
          </td> */}
        </tr>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
        <tr>
          <td className="columnsSkontrino">
            Data dhe Ora :{" "}
            {formatDateTime(
              state.UPDATED ||
                state.Updated ||
                state.Hapur ||
                state.DATA ||
                state.Data
            )}
          </td>
        </tr>

        <tr>
          <td
            className="columnsSkontrino flex "
            style={{ alignItems: "center" }}
          >
            <>
              Operator:
              <p
                className="columnsSkontrino_urdhri"
                style={{ alignItems: "center", paddingLeft: "5px" }}
              >
                {state.Operator}
              </p>
            </>
          </td>
        </tr>

        {state.Tavolina && (
          <tr>
            <td
              className="columnsSkontrino flex"
              style={{ alignItems: "center" }}
            >
              <>
                Tavolina:
                <p
                  className="columnsSkontrino_urdhri"
                  style={{ alignItems: "center", paddingLeft: "5px" }}
                >
                  {state.Tavolina}
                </p>
              </>
            </td>
          </tr>
        )}
      </thead>

      <tbody>
        <tr>
          <td colSpan={5}>
            <div className="dashed-line"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default SkontrinoUrdhriPunes;
