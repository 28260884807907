// ArticleHandler.js
import React from "react";
import { useDispatch } from "react-redux";
import mainAxios from "../../../services/axios";
import { getArtikujPag, getTotalArt } from "../../../store/globalSlice";

const ArticleHandler = ({
  state,
  handleRowClick,
  setDisabled,
  setButtonClicked,
  setButtonClickedModifiko,
  currentPage,
  numPerPage,
  username,
  showLoading,
  hideLoading,
  toast,
}) => {
  const dispatch = useDispatch();

  const handleAdd = async () => {
    try {
      showLoading();
      const response = await mainAxios.post("/art", {
        Cmimi: Number(state.Cmimi),
        Cmimi_1: Number(state.Cmimi_1),
        Kodi: state.Kodi,
        Cmimi_5: Number(state.Cmimi_5),
        Perbere: Number(state.Perbere),
        Cmimi_Bleres: Number(state.Cmimi_Bleres),
        Peshore: Number(state.Peshore),
        Aktiv: Number(state.Aktiv),
        Shumice: Number(state.Shumice),
        Pos_NeList: Number(state.Pos_NeList),
        Pakice: Number(state.Pakice),
        Distribucion: Number(state.Distribucion),
        AAM: Number(state.AAM),
        Cmimi_3: Number(state.Cmimi_3),
        Cmimi_2: Number(state.Cmimi_2),
        Operator: String(username),
        Njesi_Kodi: state.Njesi_Kodi,
        Art_Kls01_Kodi: state.Art_Kls01_Kodi,
        Pershkrim: state.Pershkrim,
        Fjale_Kyce: state.Fjale_Kyce,
        Kodi1: state.Kodi1,
        Kodi2: state.Kodi2,
        Kodi3: state.Kodi3,
        Kodi4: state.Kodi4,
        Kodi5: state.Kodi5,
        Barkod: state.Barkod,
        Skema_Kodi: state.Skema_Kodi,
        Cmimi_6: Number(state.Cmimi_6),
        Cmimi_4: Number(state.Cmimi_4),
        Tarifa_Kodi: state.Tarifa_Kodi,
        Tvsh: state.Tvsh,
      });
      if (response.status === 200) {
        handleRowClick(response.data);
        setDisabled(true);
        setButtonClicked(false);
        setButtonClickedModifiko(false);
        dispatch(
          getArtikujPag(
            `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
          )
        );
        dispatch(getTotalArt("/art/total"));

        toast.current.show({
          severity: "success",
          summary: "Artikulli u rregjistrua.",
        });
      }
    } catch (error) {
      console.error(error);

      toast.current.show({
        severity: "error",
        summary: "Gabim ne regjistrimin e artikullit.",
      });
    } finally {
      hideLoading();
    }
  };

  return {
    handleAdd,
  };
};

export default ArticleHandler;
