// SmallButtonGroup.js
import React, { useState, useEffect } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import "./SmallButton.scss";

const SmallButtonGroup = ({
  setDisabled,
  putMethod,
  setSelectedRowId,
  onAddClick,
  disabled,
  setState,
  defaultState,
  submitHandler,
  deleteMethod,
  selectedRowId,
  buttonClicked,
  setButtonClicked,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1300);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);
  return (
    <ButtonGroup size="xl" className="button-group mb-2">
      <Button
        className="smallbutton-popup"
        onClick={(e) => {
          e.preventDefault();
          setState(defaultState);
          setDisabled(false);
          setButtonClicked(true);
          onAddClick();
        }}
        disabled={buttonClicked}
      >
        <PostAddIcon />
        {!isMobile && <span className="phone-text-smallbutton">Shtim</span>}
      </Button>
      <Button
        className="smallbutton-popup"
        onClick={(e) => {
          e.preventDefault();
          setDisabled(false);
          setButtonClicked(true);
          putMethod(selectedRowId);
        }}
        disabled={buttonClicked || !selectedRowId}
      >
        <ModeEditIcon />
        {!isMobile && <span className="phone-text-smallbutton">Modifikim</span>}
      </Button>
      <Button
        className="smallbutton-popup"
        onClick={(e) => {
          e.preventDefault();
          setButtonClicked(false);
          setDisabled(true);
          deleteMethod(selectedRowId);
        }}
        disabled={buttonClicked || !selectedRowId}
      >
        <DeleteIcon />
        {!isMobile && <span className="phone-text-smallbutton">Fshije</span>}
      </Button>
      <Button
        className="smallbutton-popup"
        onClick={(e) => {
          e.preventDefault();
          setState(defaultState);
          setButtonClicked(false);
          setDisabled(true);
          setSelectedRowId(null);
        }}
        disabled={disabled}
      >
        <ClearIcon />
        {!isMobile && <span className="phone-text-smallbutton">Anullim</span>}
      </Button>
      <Button
        className="smallbutton-popup"
        onClick={(e) => {
          e.preventDefault();
          submitHandler();
        }}
        disabled={disabled}
        type="submit"
      >
        <AppRegistrationIcon />
        {!isMobile && <span className="phone-text-smallbutton">Regjistro</span>}
      </Button>
    </ButtonGroup>
  );
};

export default SmallButtonGroup;

//@@@@@@@@@@ ne nje moment te dyte duhet te implementojm increase Kodi per each small dialog (Klient, Furnitor, Sherbim, Magazine, Departament, Transport)

// -------------------------------------------------------------------------- -----------------------------------------------------------------------------

// import React, { useState, useEffect } from "react";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import Button from "@mui/material/Button";
// import PostAddIcon from "@mui/icons-material/PostAdd";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ClearIcon from "@mui/icons-material/Clear";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
// import ModeEditIcon from "@mui/icons-material/ModeEdit";
// import "./SmallButton.scss";

// import incrementKodiSh from "./minimodal/function/incrementKodi";

// const SmallButtonGroup = ({
//   setDisabled,
//   putMethod,
//   setSelectedRowId,
//   onAddClick,
//   disabled,
//   setState,
//   defaultState,
//   submitHandler,
//   deleteMethod,
//   selectedRowId,
//   buttonClicked,
//   setButtonClicked,
// }) => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

//   useEffect(() => {
//     const updateIsMobile = () => {
//       setIsMobile(window.innerWidth <= 1300);
//     };

//     window.addEventListener("resize", updateIsMobile);
//     updateIsMobile();
//     return () => {
//       window.removeEventListener("resize", updateIsMobile);
//     };
//   }, []);
//   return (
//     <ButtonGroup size="xl" className="button-group mb-2">
//       <Button
//         className="smallbutton-popup"
//         onClick={async (e) => {
//           const updatedData = await incrementKodiSh();

//                   const updatedState = {
//                     ...defaultState,
//                     Kodi: updatedData.Kodi,
//                   };

//           e.preventDefault();
//           setState(updatedState);
//           setDisabled(false);
//           setButtonClicked(true);
//           onAddClick();
//         }}
//         disabled={buttonClicked}
//       >
//         <PostAddIcon />
//         {!isMobile && <span className="phone-text-smallbutton">Shtim</span>}
//       </Button>
//       <Button
//         className="smallbutton-popup"
//         onClick={(e) => {
//           e.preventDefault();
//           setDisabled(false);
//           setButtonClicked(true);
//           putMethod(selectedRowId);
//         }}
//         disabled={buttonClicked || !selectedRowId}
//       >
//         <ModeEditIcon />
//         {!isMobile && <span className="phone-text-smallbutton">Modifikim</span>}
//       </Button>
//       <Button
//         className="smallbutton-popup"
//         onClick={(e) => {
//           e.preventDefault();
//           setButtonClicked(false);
//           setDisabled(true);
//           deleteMethod(selectedRowId);
//         }}
//         disabled={buttonClicked || !selectedRowId}
//       >
//         <DeleteIcon />
//         {!isMobile && <span className="phone-text-smallbutton">Fshije</span>}
//       </Button>
//       <Button
//         className="smallbutton-popup"
//         onClick={(e) => {
//           e.preventDefault();
//           setState(defaultState);
//           setButtonClicked(false);
//           setDisabled(true);
//           setSelectedRowId(null);
//         }}
//         disabled={disabled}
//       >
//         <ClearIcon />
//         {!isMobile && <span className="phone-text-smallbutton">Anullim</span>}
//       </Button>
//       <Button
//         className="smallbutton-popup"
//         onClick={(e) => {
//           e.preventDefault();
//           submitHandler();
//         }}
//         disabled={disabled}
//         type="submit"
//       >
//         <AppRegistrationIcon />
//         {!isMobile && <span className="phone-text-smallbutton">Regjistro</span>}
//       </Button>
//     </ButtonGroup>
//   );
// };

// export default SmallButtonGroup;
