import React, { useRef } from "react";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

// import Skontrino80 from "./skontrino80";
import Skontrino80Tatimore from "./Sontrino80Tatimore";
import DynamicTableSkontrino80 from "./DynamicFisSupermarket";
import DynamicUrdhriPunes from "./dynamicUrdhriPunes";
import SkontrinoUrdhriPunes from "./skontrinoUrdhriPunes";

const PrintoFisPos = ({
  rows,
  state,
  handleFiskalizim,
  operator,
  menyra,
  xhiro,
  nipt,
  pos,
  konfigurimeRedux,
  rowsPrint1,
}) => {
  const componentsRef2 = useRef(null);

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
  });

  return (
    <>
      {pos && (
        <Button
          onClick={() => {
            if (!state.NIVF) {
              handleFiskalizim(handlePrint2);
            } else {
              handlePrint2();
            }
          }}
          style={{
            backgroundColor: "#fff",
            color: "inherit",
            borderRadius: "20px",
            width: "100px",
          }}
        >
          Fiskalizo
        </Button>
      )}
      {xhiro && (
        <Button
          variant="contained"
          onClick={() => {
            if (!state.NIVF) {
              handleFiskalizim(handlePrint2);
            } else {
              handlePrint2();
            }
          }}
          startIcon={<PointOfSaleIcon />}
          style={{
            justifyContent: "space-between",
            margin: "5px",
          }}
        >
          Fiskalizo
        </Button>
      )}

      <div ref={componentsRef2} className="print">
        <Skontrino80Tatimore
          operator={operator}
          state={state}
          menyrapageses={menyra}
          konfigurimeRedux={konfigurimeRedux}
          nipt={nipt}
        />
        <DynamicTableSkontrino80 rows={rows} state={state} />
        {pos && rowsPrint1.length !== 0 && (
          <>
            <div className="page-break" />
            <div className="page">
              <SkontrinoUrdhriPunes
                konfigurimeRedux={konfigurimeRedux}
                state={state}
              />
              <DynamicUrdhriPunes rows={rows} state={state} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PrintoFisPos;
