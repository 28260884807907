import mainAxios from "../../../../../services/axios";

// Function to handle updating all items
const handleUpdate = async (
  handlePrint4,
  {
    fromRegister,
    dtlState,
    currentID,
    showLoading,
    hideLoading,
    showToast,
    setDtlState,
    setOpen,
    mbylleTav,
    deletedRowIds,
    setDeletedRowIds,
  }
) => {
  showLoading();
  try {
    if (fromRegister) {
      if (deletedRowIds.length > 0) {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/pos/dtl/${id}`);
        }
        // Clear deletedRowIds after deletion
        setDeletedRowIds([]);
      }
    }
    if (fromRegister && dtlState.length === 0) {
      // Delete items if there are any IDs in deletedRowIds
      if (deletedRowIds.length > 0) {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/pos/dtl/${id}`);
        }
        // Clear deletedRowIds after deletion
        setDeletedRowIds([]);
      }

      mbylleTav("Tavolina u mbyll", "info");
    } else {
      handlePrint4();
      // Prepare updated items data
      const updatedItems = dtlState.map((item) => {
        let tvsh = (item.Tvsh + 100) / 100;
        const skontoVlera =
          (item.Sasia * (item.Cmimi / tvsh) * item.Skonto) / 100;
        const vlera = item.Sasia * item.Cmimi;
        const vleraPaTvsh = item.Sasia * (item.Cmimi / tvsh) - skontoVlera;
        const tvshVlera = vlera - vleraPaTvsh;

        return {
          Master_Id: currentID,
          Id: item.Id,
          Pershkrim: item.Pershkrim,
          Sasia: item.Sasia.toFixed(2),
          Vlera: vlera.toFixed(2),
          Vlera_Pa_Tvsh: vleraPaTvsh.toFixed(2),
          Cmimi: item.Cmimi.toFixed(2),
          Tvsh_Vlera: tvshVlera.toFixed(2),
          Printuar: 1,
        };
      });

      // Sending a single PUT request for all updated items
      const response = await mainAxios.put(`/pos/dtl/bulk`, updatedItems);

      if (response.status === 200) {
        // If the request was successful, update the clicked items state
        setDtlState(dtlState);
        setOpen(false);

        showToast("Items updated successfully.");
      } else {
        showToast("Problem during updating items.");
      }
    }
  } catch (error) {
    console.error("Error updating items:", error);
    showToast("Problem during updating items.");
  } finally {
    hideLoading();
  }
};

const handlePost = async (
  handlePrint2,
  {
    dtlState,
    currentID,
    showLoading,
    hideLoading,
    showToast,
    setDtlState,
    setOpen,
    mbylleTav,
  }
) => {
  showLoading();
  handlePrint2();
  try {
    // Prepare updated items data
    const updatedItems = dtlState.map((item) => {
      let tvsh = (item.Tvsh + 100) / 100;
      const skontoVlera =
        (item.Sasia * (item.Cmimi / tvsh) * item.Skonto) / 100;
      const vlera = item.Sasia * item.Cmimi;
      const vleraPaTvsh = item.Sasia * (item.Cmimi / tvsh) - skontoVlera;
      const tvshVlera = vlera - vleraPaTvsh;

      return {
        Master_Id: currentID,
        Pershkrim: item.Pershkrim,
        Sasia: item.Sasia.toFixed(2),
        Tvsh: item.Tvsh,
        Vlera: vlera.toFixed(2),
        Vlera_Pa_Tvsh: vleraPaTvsh.toFixed(2),
        Cmimi: item.Cmimi.toFixed(2),
        Tvsh_Vlera: tvshVlera.toFixed(2),
        Printuar: 1,
      };
    });

    // Sending a single PUT request for all updated items
    const response = await mainAxios.post(`/pos/dtl/bulk`, updatedItems);

    if (response.status === 200) {
      // If the request was successful, update the clicked items state
      setDtlState(dtlState);
      mbylleTav("Tavolina u mbyll", "info");

      showToast("Items updated successfully.");
    } else {
      showToast("Problem during updating items.");
    }
  } catch (error) {
    console.error("Error updating items:", error);
    showToast("Problem during updating items.");
  } finally {
    hideLoading();
  }
}; // Return the functions for external use

export { handleUpdate, handlePost };
