import React, { useState, useRef, useEffect, useContext } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Autocomplete, Button } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import SmallButtonGroup from "../SmallButton";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "primereact/dialog";
import { TextField } from "@mui/material";
import mainAxios from "../../../services/axios";
import "./Klienti.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBankaList } from "../../../store/globalSlice";
import { createFilterOptions } from "@mui/material";
import { useUser } from "../../../zustand/common";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";

const MiniBanka = () => {
  const { user } = useUser();
  const nipt = user.nipt;

  const arkaRedux = useSelector((state) => state.globalSlice.bankaList);
  const arka = arkaRedux;

  const monedhaRedux = useSelector((state) => state.globalSlice.monedha);
  const monedha = monedhaRedux;

  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [lastKodi, setLastKodi] = useState("");

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (arka.length > 0) {
          const lastKodi = arka[arka.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("B01"); // Start with F1 if there are no existing entries
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [arka]);

  const rekorde = `${arka.length} Rekorde`;
  const defaultState = {
    Kodi: `${lastKodi}`,
    NrLL: "512",
    Pershkrim: "",
    Nr_Llogarise: "",
    Mon: "",
    Aktiv: 1,
    Adresa: "",
    Cel: "",
    Db_Org: "",
    Dega: "",
    Email: "",
    EmerKontakti: "",
    Iban: "",
    POS: "",
    Punonjes_Id: "",
    Rendit: "",
    Selektuar: "",
    ShfaqFature: "",
    Sportel_kodi: "",
    Swift: "",
    Tel: "",
  };

  const [currentAction, setCurrentAction] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const toast = useRef(null);
  // const [selectedRowData, setSelectedRowData] = useState(null);

  const llogariRedux = useSelector((state) => state.globalSlice.llogari);
  const llogari = llogariRedux;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const dispatch = useDispatch();

  const onAddClick = () => {
    setCurrentAction("add");
  };

  const putMethod = () => {
    setCurrentAction("modify");
  };

  const submitHanlder = async () => {
    try {
      showLoading();
      if (currentAction === "add") {
        // Check if selectedValue.Kodi exists in rows

        const exists = arka.some((item) => item.Kodi === state.Kodi);
        if (exists) {
          toast.current.show({
            severity: "warn",
            summary: "Kodi është i njëjtë",
            detail: "Ju lutem vendosni një Kod të ndryshëm.",
          });
          return;
        }

        if (
          !state.Kodi.trim() ||
          !state.Pershkrim.trim() ||
          !state.NrLL.trim()
        ) {
          toast.current.show({
            severity: "error",
            summary:
              "Plotesoni fushat e kerkuara: " +
              (!state.Kodi.trim() ? "Kodi, " : "") +
              (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
              (!state.NrLL.trim() ? "Llogari, " : ""),
          });
          return;
        }

        try {
          const responseMag = await mainAxios.post(`/banka/list`, {
            Kodi: state.Kodi,
            Pershkrim: state.Pershkrim,
            NrLL: state.NrLL,
            Aktiv: state.Aktiv,
            Nr_Llogarise: state.Nr_Llogarise,
            EmerKontakti: state.EmerKontakti,
            Dega: state.Dega,
            Adresa: state.Adresa,
            Swift: state.Swift,
            Mon: state.Mon,
            Iban: state.Iban,
          });

          if (responseMag.status === 200) {
            setState(defaultState);
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getBankaList("/banka/list"));
            toast.current.show({
              severity: "success",
              summary: "Banka u regjistrua.",
            });
          }
        } catch (error) {
          console.error("Error Banka: ", error);

          toast.current.show({
            severity: "error",
            summary: "Problem gjatë regjistrimit.",
          });
        }
      }
      if (currentAction === "modify") {
        try {
          const response = await mainAxios.put(`/banka/list/${selectedRowId}`, {
            Kodi: state.Kodi,
            Pershkrim: state.Pershkrim,
            NrLL: state.NrLL,
            Aktiv: state.Aktiv,
            Nr_Llogarise: state.Nr_Llogarise,
            EmerKontakti: state.EmerKontakti,
            Dega: state.Dega,
            Adresa: state.Adresa,
            Swift: state.Swift,
            Mon: state.Mon,
            Iban: state.Iban,
          });

          if (response.status === 200) {
            setDisabled(true);
            setButtonClicked(false);
            dispatch(getBankaList("/banka/list"));

            toast.current.show({
              severity: "success",
              summary: "Banka u regjistrua.",
            });
          }
        } catch (error) {
          console.error("Error Banka: ", error);

          toast.current.show({
            severity: "error",
            summary: "Problem gjatë regjistrimit.",
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Ndodhi problem.",
      });
    } finally {
      hideLoading();
    }
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=BANKA_LIST&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Ka te dhena nuk mund te fshihet",
        });

        return;
      } else {
        const resDelete = await mainAxios.delete(
          `/banka/list/${selectedRowId}`
        );

        if (resDelete.status === 200) {
          setState(defaultState);
          setSelectedRowId(null);
          toast.current.show({
            severity: "info",
            summary: "U fshi.",
          });
          dispatch(getBankaList("/banka/list"));
        }
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Nuk mund te fshihet.",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "NrLL", title: "Llogari" },
    { field: "Nr_Llogarise", title: "Nr_Llogarise" },
    { field: "Iban", title: "Iban" },
    { field: "Swift", title: "Swift" },
    { field: "Adresa", title: "Adresa" },
    { field: "Mon", title: "Monedha" },
    { field: " Dega", title: "Dega" },
    { field: "EmerKontakti", title: "EmerKontakti" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      NrLL: rowData.NrLL,
      Mon: rowData.Mon,
      Nr_Llogarise: rowData.Nr_Llogarise,
      Adresa: rowData.Adresa,
      Swift: rowData.Swift,
      Iban: rowData.Iban,
      Dega: rowData.Dega,
      EmerKontakti: rowData.EmerKontakti,
    });
    setSelectedRowId(rowData.Id);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        {/* <AddIcon
          fontSize="small"
          className="cursor-pointer"
          
        >
          Open
        </AddIcon> */}
        <Button fontSize="small" onClick={() => onClick("displayResponsive")}>
          <AddCircleIcon />
        </Button>

        <Dialog
          header="Banka"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
          className="custom-dialog"
        >
          <div className=" mt-2">
            <SmallButtonGroup
              setSelectedRowId={setSelectedRowId}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              putMethod={putMethod}
              onAddClick={onAddClick}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={state?.Kodi || ""}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim || ""}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Nr_Llogarise"
              size="small"
              value={state?.Nr_Llogarise}
              onChange={(e) => handleChange("Nr_Llogarise", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Iban"
              size="small"
              value={state?.Iban}
              onChange={(e) => handleChange("Iban", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Swift"
              size="small"
              value={state?.Swift}
              onChange={(e) => handleChange("Swift", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Adresa"
              size="small"
              value={state?.Adresa}
              onChange={(e) => handleChange("Adresa", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Dega"
              size="small"
              value={state?.Dega}
              onChange={(e) => handleChange("Dega", e.target.value)}
              className="mt-2 w-7/12"
            />

            <TextField
              disabled={disabled}
              type="text"
              variant="outlined"
              label="Emer Kontakti"
              size="small"
              value={state?.EmerKontakti}
              onChange={(e) => handleChange("EmerKontakti", e.target.value)}
              className="mt-2 w-7/12"
            />

            <div className=" rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative">
              <Autocomplete
                disablePortal
                disabled={disabled}
                className="centered-element mt-2"
                id="combo-box-Mon5"
                options={llogari}
                filterOptions={filterOptions}
                getOptionLabel={(option) =>
                  option ? option.Kodi + "-" + option.Pershkrim : ""
                }
                onChange={(e, newValue) =>
                  handleChange("NrLL", newValue ? newValue.Kodi : "")
                }
                value={
                  llogari.find((qytet) => qytet.Kodi === state?.NrLL) || ""
                }
                size="small"
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Llogari" />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>

            <div className=" rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-Mon6"
                className="centered-element mt-2"
                options={monedha}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChange("Mon", newValue ? newValue.Kodi : "")
                }
                value={
                  monedha.find((qytet) => qytet.Kodi === state?.Mon) || null
                }
                size="small"
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Monedha" />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>
            <div className="border p-2 flex flex-col mt-2">
              <DataTable
                className="datatable-cell"
                editMode="cell"
                selectionMode="single"
                selection={selectedProduct1}
                onSelectionChange={() => setSelectedProduct1(defaultState)}
                size="small"
                scrollHeight="10rem"
                value={arka}
                responsiveLayout="scroll"
                contextMenuSelection={selectedProduct}
                contextselection={selectedProduct}
                onContextMenuSelectionChange={(e) =>
                  setSelectedProduct(defaultState)
                }
                onContextMenu={(e) => cm.current.show(e.originalEvent)}
                onRowClick={(e) => handleRowClick(e.data)}
              >
                {dynamicColumns}
              </DataTable>
            </div>
            <Toast ref={toast} />
          </div>
          <span>{rekorde}</span>
        </Dialog>
      </form>
    </div>
  );
};

export default MiniBanka;
