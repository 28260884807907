import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./print80Skontrino.scss";

const DynamicUrdhriPunes = ({ rows }) => {
  const DisplayData = rows.map((info, index) => {
    const rowNumber = index + 1;
    return (
      <tr key={rowNumber}>
        <td className="columnsPershkrim_urdhri">{info.Sasia} x </td>
        <td className="columnsPershkrim_urdhri">{info.Pershkrim}</td>
      </tr>
    );
  });

  return (
    <table className="hide-border">
      <thead>
        <tr
          className="table-head"
          style={{
            textAlign: "center",
          }}
        >
          <th className="th">Sasia</th>
          <th className="th">Artikull</th>
        </tr>
      </thead>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>

      <tbody>{DisplayData}</tbody>
      <tr>
        <td colSpan={5}>
          <div className="div_line"></div>
        </td>
      </tr>

      <tr>
        <td colSpan={5}>
          <div className="dashed-line"></div>
        </td>
      </tr>
      <tfoot>
        <tr>
          <td colSpan={5} className="footer">
            Gjeneruar nga Parid Smart Solution
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default DynamicUrdhriPunes;
