import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";
import "../MainComponent/MainComponent.scss";
import { Toast } from "primereact/toast";
import HandleItemClick from "./HandleItemClick";
import HandleInputChange from "./HandleInputChange";
import HandleInputCmimiChange from "./HandleInputCmimiChange";
import mainAxios from "../../../../../services/axios";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";
import PrintoPdf1 from "../../../../../components/ReactPDF/PrintoPdf1";
import PrintoFisPos from "../../../../../components/ReactPDF/PrintoFisPos";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { handlePost, handleUpdate } from "./UpdatePostItems";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupMenu from "./PopupMenu";
import { formatDate } from "../DateUtils";
import ShtoKlient from "../ShtoKlient/ShtoKlient";
import { handleFiskalizim } from "./handleFiskalizim";

const LeftBar = ({
  showLoading,
  hideLoading,
  currentID,
  konfigurimeRedux,
  handleClose,
  mbylleTav,
  setPrintPorosia,
  operator,
  menyra,
  nipt,
  klienti,
  rows,
}) => {
  const {
    currentAction,
    setOpen,
    aExistTable,
    dtlState,
    setDtlState,
    gjeneroFatureAnulluese,
    setGjeneroFatureAnulluese,
    state,
    setState,
    dtlNum,
    defaultState,
  } = useMyContext();
  const [totaliFatura, setTotaliFatura] = useState(false);
  const [totalBtnDisabled, setTotalBtnDisabled] = useState(false);
  const toast = useRef(null);
  const [filteredItems2, setFilteredItems2] = useState([]);
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const [totalCmimi, setTotalCmimi] = useState(0);

  useEffect(() => {
    // Calculate the sum of Cmimi values for all items in clickedItems
    const sumCmimi = dtlState.reduce(
      (acc, item) => acc + (item.Sasia * item.Cmimi || 0),
      0
    );
    setTotalCmimi(sumCmimi.toFixed(2));
  }, [dtlState]);

  const shikoTotalin = () => {
    setTotaliFatura(!totaliFatura);
  };

  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 1000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  useEffect(() => {
    if (gjeneroFatureAnulluese) {
      const filteredItems = dtlState.filter((item) => item.Printuar === 1);
      setFilteredItems2(filteredItems);
    } else {
      const filteredItems = dtlState.filter((item) => item.Printuar !== 1);
      setFilteredItems2(filteredItems);
    }
  }, [dtlState, gjeneroFatureAnulluese]);

  const handleUpdateAllItems = (handlePrint4, { fromRegister }) => {
    handleUpdate(
      handlePrint4, // Pass handlePrint2 here
      {
        fromRegister,
        dtlState,
        currentID,
        showLoading,
        hideLoading,
        state,
        setState,
        showToast,
        setDtlState,
        setOpen,
        mbylleTav,
        deletedRowIds,
        setDeletedRowIds,
      }
    );
  };

  const handlePostAllItems = (handlePrint2) => {
    handlePost(
      handlePrint2, // Pass handlePrint2 here
      {
        dtlState,
        currentID,
        state,
        setState,
        showLoading,
        hideLoading,
        showToast,
        setDtlState,
        setOpen,
        mbylleTav,
      }
    );
  };

  const getDtl = () => {
    setTotaliFatura(false);
    setFilteredItems2(dtlState);
    setTotalBtnDisabled(true);
    setGjeneroFatureAnulluese(true);
  };

  const handleFis = (handlePrint2) => {
    handleFiskalizim(
      handlePrint2, // Pass handlePrint2 here
      {
        rows,
        formatDate,
        state,
        setState,
        defaultState,
        dtlState,
        currentID,
        state,
        setState,
        showLoading,
        hideLoading,
        showToast,
        setDtlState,
        setOpen,
        mbylleTav,
      }
    );
  };

  return (
    <>
      <div className="squares-container">
        {gjeneroFatureAnulluese && (
          <div
            style={{
              backgroundColor: "#FF8A8A",
              marginTop: "20px",
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              width: "100%",
            }}
          >
            <h1>Artikujt Anullues</h1>
          </div>
        )}
        {totaliFatura
          ? dtlState.map((item, index) => (
              <div className="square1" key={index}>
                <Toast ref={toast} position="top-center" />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <div
                    className="pershkrimArt_leftbar"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.overflow = "visible")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.overflow = "hidden")
                    }
                  >
                    {item.Pershkrim}
                  </div>

                  <div className="cmimi_leftbar">
                    <div>
                      {Number.isFinite(item.Sasia)
                        ? Number.isInteger(item.Sasia)
                          ? item.Sasia
                          : item.Sasia.toFixed(2)
                        : ""}
                    </div>
                  </div>

                  <div className="shumezim_leftbar">
                    <ClearIcon />
                  </div>

                  <div className="cmimi_leftbar">
                    <div>
                      {Number.isFinite(item.Cmimi)
                        ? Number.isInteger(item.Cmimi)
                          ? item.Cmimi
                          : item.Cmimi.toFixed(2)
                        : ""}
                    </div>
                  </div>
                  {item.Printuar === 1 ? (
                    <div className="mr-5">
                      <span>Printuar</span>
                    </div>
                  ) : (
                    <div className="mr-5">
                      <span>Aktiv</span>
                    </div>
                  )}
                </Grid>
              </div>
            ))
          : filteredItems2.map((item, index) => (
              <div className="square1" key={index}>
                <Toast ref={toast} position="top-center" />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <div
                    className="pershkrimArt_leftbar"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.overflow = "visible")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.overflow = "hidden")
                    }
                  >
                    {item.Pershkrim}
                  </div>

                  <HandleInputChange
                    item={item}
                    showLoading={showLoading}
                    clickedItems={dtlState}
                    hideLoading={hideLoading}
                    setClickedItems={setDtlState}
                    index={index}
                  />

                  <div className="shumezim_leftbar">
                    <ClearIcon />
                  </div>

                  <HandleInputCmimiChange
                    item={item}
                    showLoading={showLoading}
                    clickedItems={dtlState}
                    hideLoading={hideLoading}
                    setClickedItems={setDtlState}
                    index={index}
                  />

                  <HandleItemClick
                    item={item}
                    setDeletedRowIds={setDeletedRowIds}
                    showLoading={showLoading}
                    hideLoading={hideLoading}
                    showToast={showToast}
                    setClickedItems={setDtlState}
                    clickedItems={dtlState}
                    currentAction={currentAction}
                  />
                </Grid>
              </div>
            ))}
      </div>

      <div className="footer">
        <hr className="hr_pos" />
        <div
          className="total_amount flex"
          style={{ justifyContent: "space-between" }}
        >
          <div className="total">
            <h1>Total</h1>
          </div>
          <div className="amount">
            <h2>{totalCmimi} Lek</h2>
          </div>
        </div>
        {!totalBtnDisabled ? (
          <div
            className="background_button flex"
            style={{ justifyContent: "space-between" }}
          >
            <PrintoPdf1
              filteredItems2={filteredItems2}
              handleUpdateAllItems={handleUpdateAllItems}
              konfigurimeRedux={konfigurimeRedux}
              handleClose={handleClose}
              posBar={true}
              // mbylleTav={mbylleTav}
              handlePostAllItems={handlePostAllItems}
              fatureThjesht={true}
              setPrintPorosia={setPrintPorosia}
              className="print"
              rows={rows}
              rowsCheck={dtlState}
              rowsPrint1={filteredItems2}
              state={state}
              totaliFatura={totaliFatura}
              operator={operator}
              menyrapageses={menyra}
              nipt={nipt}
              showToast={showToast}
            />

            <ShtoKlient klienti={klienti} />

            {aExistTable && (
              <PopupMenu
                shikoTotalin={shikoTotalin}
                getDtl={getDtl}
                totaliFatura={totaliFatura}
                totalBtnDisabled={totalBtnDisabled}
              />
            )}

            <PrintoFisPos
              rowsPrint1={filteredItems2}
              pos={true}
              handleFiskalizim={handleFis}
              rows={rows}
              state={state}
              operator={operator}
              menyra={menyra}
              konfigurimeRedux={konfigurimeRedux}
              nipt={nipt}
            />
          </div>
        ) : (
          <div
            className="background_button flex"
            style={{ justifyContent: "space-between" }}
          >
            <PrintoPdf1
              totalBtnDisabled={totalBtnDisabled}
              handleUpdateAllItems={handleUpdateAllItems}
              konfigurimeRedux={konfigurimeRedux}
              handleClose={handleClose}
              posBar={true}
              // mbylleTav={mbylleTav}
              handlePostAllItems={handlePostAllItems}
              fatureThjesht={true}
              setPrintPorosia={setPrintPorosia}
              className="print"
              rows={rows}
              rowsPrint1={filteredItems2}
              state={state}
              operator={operator}
              menyrapageses={menyra}
              nipt={nipt}
              showToast={showToast}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LeftBar;
