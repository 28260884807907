import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import useStorage from "../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import "./Celje.scss";
import { Toast } from "primereact/toast";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TabMainCelje from "./PaneliTabeveCelje/TabMainCelje";
import TabTjeraCelje from "./PaneliTabeveCelje/TabTjera";
import SmallButtonGroupC from "./SmallButtonGroupC";
import { getPerdorues } from "../../store/globalSlice";
import Footer from "../../components/Navbar/Footer";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PrimeGridKF from "./PrimeGridKlient";
import TabWebRights from "./PaneliTabeveCelje/TabWebRights";
import SidebarCelje from "./SideBarCelje";
import { useUser } from "../../zustand/common";

const PerdoruesC = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;

  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const userCurrent = user.username;

  const [lastData, setLastData] = useState({});
  const toast = useRef(null);

  // Redux: Get client data from global slice
  const perdoruesRedux = useSelector((state) => state.globalSlice.perdorues);

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();

        setdata(perdoruesRedux);
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
        hideLoading();
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [perdoruesRedux, showLoading, hideLoading]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "Operator", title: "Operator" },
    { field: "Pershkrim", title: "Pershkrim" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatMagazines"
  );
  let defaultState = {
    AAM: null,
    Adresa: null,
    Aktiv: null,
    Arka_Kodi: null,
    BakshishPerqindje: null,
    Banka_Kodi: null,
    Blerje_Arka_Banka_Kodi: null,
    Blerje_KlFu_Kodi: "F001",
    Blerje_Menyra_Pageses_Id: null,
    Blerje_Mon: null,
    Blerje_Paguar_Ne: "BANKA",
    Cel: null,
    Dep_Kodi: null,
    Email: null,
    EshteBanakier: null,
    EshteKasier: null,
    EshteNeList: null,
    Fjalekalim: null,
    Gjuha: "SHQIP",
    HAPAUTOMATIKISHT: null, //num
    Klient_Kodi: null,
    Magazina_Drejt: null,
    Magazina_Kodi: null,
    MenuRibbonSipasMenuPeme: null,
    Menu_ID: null, //num
    MobileActiv: null,
    Monedha_Kodi: null,
    MundTeBejLogOut: null,
    Ndermarrje_Drejt: null,
    Ndermarrje_Sel: nipt,
    NotForSync: null,
    Operator: "",
    POSPIN: null,
    PagaOre: null,
    Pakice: null,
    Password: "",
    Pershkrim: "",
    Punonjes_ID: null,
    Qyteti_Kodi: null,
    Raporte_Drejt: null,
    Scripter_Indeksi: null,
    SelectWhereData: null,
    Selektuar: null,
    ShfaqMenuPeme: null,
    ShfaqMenuRibbon: null,
    Shitje_Arka_Banka_Kodi: null,
    Shitje_KlFu_Kodi: null,
    Shitje_Menyra_Pageses_Id: null,
    Shitje_Mon: null,
    Shitje_Paguar_Ne: null,
    Shteti_Kodi: null,
    Shumice: null,
    Skin: null,
    Sportel_Kodi: null,
    Station: null,
    Tel: null,
    TipMenu: null, //num
    Tip_Cmimi: null,
    Updated: null,
    Users_Grup_Id: null, //num
    fisBusinessAdress: null,
    fisBusinessCode: null,
    fisBusinessNIPT: null,
    fisBusinessName: null,
    fisDevPOSOperator: null,
    fisOperator: null,
    fisPassword: null,
    fisTCRCode: null,
    mArka: null,
    mArt: null,
    mArtListRight: null, //num
    mAutomaticOpen: null, //num
    mBanka: null,
    mBarRestorant: null,
    mBlueToothTest: null,
    mChangePrice: null,
    mCloseDocumentAfterPrint: null,
    mCloseDocumentAfterSave: null,
    mClosePOS: null,
    mCmimiBleresShow: null,
    mCmimiJoneShow: null,
    mConfigDB: null,
    mDBSinkronizim: null,
    mDetyrat: null,
    mDevicesCanLogin: null,
    mFLD: null,
    mFLH: null,
    mFTBL: null,
    mFTSH: null,
    mInventory: null,
    mNotRound: null,
    mPOS: null,
    mPOSChangePrice: null,
    mPOSOnlyOwnDocuments: null,
    mPorosi: null,
    mPreOrder: null,
    mProdhim: null,
    mQRCodeFTSH: null,
    mRaportFurnitor: null,
    mRaportKlient: null,
    mRaportPorosi: null,
    mRaporteTePergjithshme: null,
    mReferencat: null,
    mReportArt: null,
    mReportFTBL: null,
    mReportFTSH: null,
    mReportPOS: null,
    mReports: null,
    mScanLocalDB: null,
    mSearchClientFromCalls: null,
    mShowClientInPOS: null,
    mShowEmployeeInPOS: null,
    mShowNavigator: null,
    mUploadToServer: null,
    mUserManager: null,
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [visibleRight, setVisibleRight] = useState(false);

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Perdoruesi
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SidebarCelje
          currentAction={currentAction}
          toast={toast}
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        />
      </div>
    );
  };

  const deleteMethod = async (e) => {
    try {
      const response = await mainAxios.delete(`/perdorues/${state.Id}`);

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        toast.current.show({
          severity: "info",
          summary: "u fshi",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u fshi." + error.message,
      });
    }
  };

  const handleAdd = async (e) => {
    if (
      !state.Operator.trim() ||
      !state.Pershkrim.trim() ||
      !state.Password.trim()
    ) {
      toast.current.show({
        severity: "warn",
        summary:
          "Plotesoni fushat: " +
          (!state.Operator.trim() ? "Perdorues, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
          (!state.Password.trim() ? "Fjalekalim" : ""),
      });
      return;
    }
    try {
      // Adjust this object to match the expected fields for the endpoint
      const payload = {
        Aktiv: Number(state.Aktiv),
        Pershkrim: state.Pershkrim,
        Operator: state.Operator,
        Password: state.Password,
        Gjuha: state.Gjuha,
        Email: state.Email,
        HAPAUTOMATIKISHT: Number(state.HAPAUTOMATIKISHT),
        MobileActiv: state.MobileActiv,
        Ndermarrje_Sel: state.Ndermarrje_Sel,
        MundTeBejLogOut: true,
        Menu_ID: 1,
        Pakice: true,
        Shitje_Paguar_Ne: "ARKA",
        Shitje_KlFu_Kodi: "K001",
        Shumice: true,
        Tip_Cmimi: "CMIMI",
        TipMenu: 2,
        Updated_Nga_Operator: String(userCurrent),
        Users_Grup_Id: state.Users_Grup_Id,
        fisOperator: state.fisOperator,
        mArka: state.mArka,
        mArt: state.mArt,
        mArtListRight: state.mArtListRight, // num
        mAutomaticOpen: state.mAutomaticOpen, // num
        mBanka: state.mBanka,
        mBarRestorant: state.mBarRestorant,
        mBlueToothTest: state.mBlueToothTest,
        mChangePrice: state.mChangePrice,
        mCloseDocumentAfterPrint: state.mCloseDocumentAfterPrint,
        mCloseDocumentAfterSave: state.mCloseDocumentAfterSave,
        mClosePOS: state.mClosePOS,
        mCmimiBleresShow: state.mCmimiBleresShow,
        mCmimiJoneShow: state.mCmimiJoneShow,
        mConfigDB: state.mConfigDB,
        mDBSinkronizim: state.mDBSinkronizim,
        mDetyrat: state.mDetyrat,
        mDevicesCanLogin: state.mDevicesCanLogin,
        mFLD: state.mFLD,
        mFLH: state.mFLH,
        mFTBL: state.mFTBL,
        mFTSH: state.mFTSH,
        mInventory: state.mInventory,
        mNotRound: state.mNotRound,
        mPOS: state.mPOS,
        mPOSChangePrice: state.mPOSChangePrice,
        mPOSOnlyOwnDocuments: state.mPOSOnlyOwnDocuments,
        mPorosi: state.mPorosi,
        mPreOrder: state.mPreOrder,
        mProdhim: state.mProdhim,
        mQRCodeFTSH: state.mQRCodeFTSH,
        mRaportFurnitor: state.mRaportFurnitor,
        mRaportKlient: state.mRaportKlient,
        mRaportPorosi: state.mRaportPorosi,
        mRaporteTePergjithshme: state.mRaporteTePergjithshme,
        mReferencat: state.mReferencat,
        mReportArt: state.mReportArt,
        mReportFTBL: state.mReportFTBL,
        mReportFTSH: state.mReportFTSH,
        mReportPOS: state.mReportPOS,
        mReports: state.mReports,
        mScanLocalDB: state.mScanLocalDB,
        mSearchClientFromCalls: state.mSearchClientFromCalls,
        mShowClientInPOS: state.mShowClientInPOS,
        mShowEmployeeInPOS: state.mShowEmployeeInPOS,
        mShowNavigator: state.mShowNavigator,
        mUploadToServer: state.mUploadToServer,
        mUserManager: state.mUserManager,
      };

      const response = await mainAxios.post(`/perdorues`, payload);

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U rregjistrua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u regjistrua." + error.message,
      });
    }
  };

  const handleModify = async (e) => {
    if (
      !state.Operator.trim() ||
      !state.Pershkrim.trim() ||
      !state.Password.trim()
    ) {
      toast.current.show({
        severity: "warn",
        summary:
          "Plotesoni fushat: " +
          (!state.Operator.trim() ? "Perdorues, " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim, " : "") +
          (!state.Password.trim() ? "Fjalekalim" : ""),
      });
      return;
    }

    try {
      const response = await mainAxios.put(`/perdorues/${state.Id}`, {
        Aktiv: Number(state.Aktiv),
        Pershkrim: state.Pershkrim,
        Operator: state.Operator,
        Password: state.Password,
        Gjuha: state.Gjuha,
        Email: state.Email,
        HAPAUTOMATIKISHT: Number(state.HAPAUTOMATIKISHT),
        MobileActiv: state.MobileActiv,
        Ndermarrje_Sel: state.Ndermarrje_Sel,
        MundTeBejLogOut: true,
        Menu_ID: 1,
        Pakice: true,
        Shitje_Paguar_Ne: "ARKA",
        Shitje_KlFu_Kodi: "K001",
        Shumice: true,
        TipMenu: 2,
        Tip_Cmimi: "CMIMI",
        Updated_Nga_Operator: String(userCurrent),
        Users_Grup_Id: state.Users_Grup_Id,
        fisOperator: state.fisOperator,
        mArka: state.mArka,
        mArt: state.mArt,
        mArtListRight: state.mArtListRight, // num
        mAutomaticOpen: state.mAutomaticOpen, // num
        mBanka: state.mBanka,
        mBarRestorant: state.mBarRestorant,
        mBlueToothTest: state.mBlueToothTest,
        mChangePrice: state.mChangePrice,
        mCloseDocumentAfterPrint: state.mCloseDocumentAfterPrint,
        mCloseDocumentAfterSave: state.mCloseDocumentAfterSave,
        mClosePOS: state.mClosePOS,
        mCmimiBleresShow: state.mCmimiBleresShow,
        mCmimiJoneShow: state.mCmimiJoneShow,
        mConfigDB: state.mConfigDB,
        mDBSinkronizim: state.mDBSinkronizim,
        mDetyrat: state.mDetyrat,
        mDevicesCanLogin: state.mDevicesCanLogin,
        mFLD: state.mFLD,
        mFLH: state.mFLH,
        mFTBL: state.mFTBL,
        mFTSH: state.mFTSH,
        mInventory: state.mInventory,
        mNotRound: state.mNotRound,
        mPOS: state.mPOS,
        mPOSChangePrice: state.mPOSChangePrice,
        mPOSOnlyOwnDocuments: state.mPOSOnlyOwnDocuments,
        mPorosi: state.mPorosi,
        mPreOrder: state.mPreOrder,
        mProdhim: state.mProdhim,
        mQRCodeFTSH: state.mQRCodeFTSH,
        mRaportFurnitor: state.mRaportFurnitor,
        mRaportKlient: state.mRaportKlient,
        mRaportPorosi: state.mRaportPorosi,
        mRaporteTePergjithshme: state.mRaporteTePergjithshme,
        mReferencat: state.mReferencat,
        mReportArt: state.mReportArt,
        mReportFTBL: state.mReportFTBL,
        mReportFTSH: state.mReportFTSH,
        mReportPOS: state.mReportPOS,
        mReports: state.mReports,
        mScanLocalDB: state.mScanLocalDB,
        mSearchClientFromCalls: state.mSearchClientFromCalls,
        mShowClientInPOS: state.mShowClientInPOS,
        mShowEmployeeInPOS: state.mShowEmployeeInPOS,
        mShowNavigator: state.mShowNavigator,
        mUploadToServer: state.mUploadToServer,
        mUserManager: state.mUserManager,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getPerdorues("/get/perdoruesi"));
        setCurrentAction(null);
        toast.current.show({
          severity: "success",
          summary: "U modifikua ",
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Nuk u modifikua " + error.message,
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      {/* {isChecked && ( */}
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.Id}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
        // deleteHandler={deleteHandler}
      />
    </div>
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";
  const button7Text = isMobile ? "" : "Web Rights";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      includeButton7={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      button7Text={button7Text}
      button7Icon={<FactCheckIcon />}
    />
  );

  const TabiKryesor = (
    <TabMainCelje
      fromPerdorues={true}
      state={state}
      TextGrupi={true}
      TextEmri={true}
      TextPerdorues={true}
      TextFjalkalim={true}
      disabled={disabled}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraCelje
        isTransport={true}
        TextOperator={true}
        AktivM={true}
        AktivBarPos={true}
        TextCel={true}
        setState={setState}
        TextEmail={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        state={state}
      />
      <TabWebRights
        Aktiv1={true}
        Aktiv2={true}
        Aktiv3={true}
        Aktiv4={true}
        Aktiv5={true}
        Aktiv6={true}
        Aktiv7={true}
        setState={setState}
        TextEmail={true}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
      />
    </div>
  );

  return (
    <div className="mainDiv_ftsh-c">
      <NavBar />
      <Toast ref={toast} />
      <div className={isMobile ? null : "template"}>
        <div className="p-2 main-container-c">
          <div className="top_panel_fsh-c">
            <Panel
              headerTemplate={template}
              toggleable
              style={{ height: "100%" }}
            >
              <div className="bg-white" style={{ height: "100%" }}>
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve-c">
                      <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                      <div className="paneli-fis-print-c"></div>
                    </div>
                    <div className={"below-tabs-c"}>
                      <div className="below-tabs1-c">{TabiKryesor}</div>
                      <div className="below-tabs2-c">{smallTabsKM}</div>
                      <div className="below-tabs3-c"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>

          <div className={!isMobile ? "middle_fsh-c" : null}></div>

          <div className="dataTable_fsh-c">
            <PrimeGridKF
              gridKey="Perdorues"
              data={data}
              defaultState={defaultState}
              isEditable={true}
              defaultColumnsStorage={defaultColumnsStorage}
              columns={columns}
              setColumns={setColumns}
              setLastData={setLastData}
              disabled={disabled}
              setdata={setdata}
              setState={setState}
              rows={rows}
              state={state}
              setRows={setRows}
              selectedColumns={selectedColumns}
              setselectedcolumns={setselectedcolumns}
              currentAction={currentAction}
            />
          </div>
        </div>
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(PerdoruesC);
