import { InputText } from "primereact/inputtext";
import React, { useRef, useEffect, useState } from "react";

const HandleInputChange = ({
  item,
  showLoading,
  clickedItems,
  hideLoading,
  setClickedItems,
  index,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(item.Sasia || "0");

  // Select all text on focus
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("focus", () => {
        inputRef.current.select();
      });
    }
  }, []);

  useEffect(() => {
    setInputValue(item.Sasia || "0");
  }, [item.Sasia]);

  const handleInputChange = (value) => {
    setInputValue(value);
    if (value === "") {
      updateSasia(1);
      return;
    }
    const newValue = parseFloat(value);
    if (isNaN(newValue) || newValue < 0) {
      updateSasia(1);
    } else {
      updateSasia(newValue);
    }
  };

  const updateSasia = (value) => {
    let updatedRowData = [...clickedItems];
    updatedRowData[index].Sasia = value;

    try {
      showLoading();
      setClickedItems(updatedRowData);
    } catch (error) {
      console.error("Error handling item:", error);
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="number_leftbar">
      <InputText
        ref={inputRef}
        type="number"
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        className="input_sasia"
        id="Sasia_2"
        step="0.01"
      />
    </div>
  );
};

export default HandleInputChange;
