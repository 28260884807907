import React, { useEffect, useState, useRef, useContext } from "react";
import "../POSE.scss";
import FullScreenDialog from "./FullScreenDialog";
import mainAxios from "../../../../services/axios";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
import DivComponent from "./DivComponent.js/DivComponent";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDate } from "./DateUtils";
import { useUser } from "../../../../zustand/common";
import TableHandler from "./MainComponent/TableHandler";
import { useMyContext } from "../../../../components/context/MyStatePOSContext";
import { useArtContext } from "../../../../components/context/ArtikullContextPosBar";

const KthePanelet = ({
  panelName,
  klienti,
  btnSektor,
  btntavolinat,
  tavolinaFundit,
  tavolinaObjects,
  fetchPos,
  kls01,
  loading,
  setLoading,
  arka,
  menyra,
  isOnline,
  // storeOfflineRequest,
  isMobile,
  newId,
  setNewId,
}) => {
  const {
    dtlState,
    open,
    setOpen,
    dtlNum,
    state,
    setState,
    defaultState,
    setCurrentPage,
  } = useMyContext();
  const { setFromKthePanelet } = useArtContext();
  const { user } = useUser();
  const operator = user.username;
  const toast = useRef(null);
  const [divs, setDivs] = useState([]);
  const [ora, setOra] = useState("");
  const timeOnly = ora.slice(11, 19);
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [numTav, setNumTav] = useState("");
  const [openedTable, setOpenedTable] = useState([]);

  const [error, setError] = useState(false);
  const [loadingLeft, setLoading2] = useState(true);

  const [totalOfflineCmimi, setTotalOfflineCmimi] = useState(0);

  // when ofline
  const [offlineData, setDataOffline] = useState([]);

  // Toast Notification dynamic

  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      detail: options.detail || "",
      life: options.life || 2000,
      position: "top-center",
      closable: options.closable || false,
      ...options,
    });
  };

  const currentDate = formatDate(new Date());

  const currentDateAndTime = formatDate(new Date(), true);

  useEffect(() => {
    if (panelName === btnSektor) {
      const localDivs =
        JSON.parse(localStorage.getItem(`divs${panelName}`)) || [];
      setDivs(localDivs);
    }
  }, [panelName, btnSektor]);

  const mbylleTav = async (text, severity) => {
    setLoading(true);

    const { ...postDataWithoutId } = {
      Aktiv: 0,
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };
    try {
      const response = await mainAxios.put(`/pos/${newId}`, postDataWithoutId, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status !== 200) {
        showToast("Ndodhi problem me mbylljen e tavolines", {
          severity: "error",
        });
      } else {
        showToast(text, { severity: severity });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      if (open) {
        setOpen(false);
      }
      setLoading(false);
    }
  };

  const handleClose = async () => {
    if (dtlNum === 0 && open) {
      await mbylleTav("Nuk keni asnje artikull, tavolina u mbyll", "info");
      setCurrentPage(1);
      setFromKthePanelet(true);
      return; // Exit early if no items are present
    }

    if (open) {
      // Check if dtlState is valid
      if (!dtlState?.length) {
        await mbylleTav("Nuk keni asnje artikull, tavolina u mbyll", "info");
        setCurrentPage(1);
        setFromKthePanelet(true);
        return; // Exit early if there are no items
      }

      const foundItem = dtlState.find((item) => item.Printuar === 1);

      if (foundItem) {
        setCurrentPage(1);
        setFromKthePanelet(true);
        setOpen(false);
      } else {
        setCurrentPage(1);
        setFromKthePanelet(true);
        await mbylleTav("Nuk keni asnje artikull, tavolina u mbyll", "info");
      }
    }
  };

  const handleSelectDiv = (div) => {
    setSelectedDiv(div);
  };

  if (panelName !== btnSektor) return null;

  const numTavolinave = btntavolinat[panelName] || [];

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className="kthePAnelet">
          <Toast ref={toast} />
          <div
            className="kthePAnelet1"
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: isMobile ? "95%" : undefined,
              overflowY: isMobile ? "scroll" : "hidden",
            }}
          >
            {numTavolinave.map((tableNumber) => {
              const tableData = tavolinaObjects.find(
                (item) => item.Tavolina === tableNumber
              );
              return (
                <TableHandler
                  isMobile={isMobile}
                  tableData={tableData}
                  tableNumber={tableNumber}
                  tavolinaObjects={tavolinaObjects}
                  operator={operator}
                  state={state}
                  currentDate={currentDate}
                  currentDateAndTime={currentDateAndTime}
                  isOnline={isOnline}
                  showToast={showToast}
                  Toast={Toast}
                  setState={setState}
                  setNewId={setNewId}
                  setOra={setOra}
                  setNumTav={setNumTav}
                  setDivs={setDivs}
                  tavolinaFundit={tavolinaFundit}
                  handleClose={handleClose}
                  setLoading2={setLoading2}
                  setLoading={setLoading}
                  setError={setError}
                  setOpen={setOpen}
                  setDataOffline={setDataOffline}
                  divs={divs}
                  openedTable={openedTable}
                  setOpenedTable={setOpenedTable}
                />
              );
            })}
          </div>

          <FullScreenDialog
            open={open}
            newId={newId}
            handleClose={handleClose}
            mbylleTav={mbylleTav}
            numTav={numTav}
            state={state}
            setState={setState}
            formatDate={formatDate}
            isMobile={isMobile}
            timeOnly={timeOnly}
            klienti={klienti}
            showToast={showToast}
            fetchPos={fetchPos}
            error={error}
            setError={setError}
            kls01={kls01}
            menyra={menyra}
            setLoading={setLoading}
            loading={loading}
            loadingLeft={loadingLeft}
            setLoading2={setLoading2}
            arka={arka}
            setOpen={setOpen}
            isOnline={isOnline}
            offlineData={offlineData}
            setTotalOfflineCmimi={setTotalOfflineCmimi}
          />

          {divs.map((div) => (
            <DivComponent
              key={div.id}
              div={div}
              selectedDiv={selectedDiv}
              handleSelectDiv={handleSelectDiv}
              customizeDiv={""}
              enable={false}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default KthePanelet;
