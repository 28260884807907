import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Tarifat from "./MiniModalArtikuj/Tepergjithshme/Tarifat";
import Grupi from "./MiniModalArtikuj/Tepergjithshme/Grupi";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import "./TePergjithshme.scss";

const TextFieldsTePergjithshme = ({
  state,
  setState,
  disabled,
  handleChange,
  updateRowData,
  handleChangeTarifa,
  isEditingAllowed,
  initialLength,
  setInitialLength,
}) => {
  // tarifa redux
  const tarifaRedux = useSelector((state) => state.globalSlice.tarifa);
  const tarifa = tarifaRedux;

  // grupi redux
  const grupiRedux = useSelector((state) => state.globalSlice.grupi);
  const grupi = grupiRedux;

  //   const clearValue = (key) => {
  //     setState({ [key]: null });
  //     handleChange({ [key]: null });
  //   };

  const handleChangeSelect = (key, value) => {
    if (isEditingAllowed) {
      updateRowData({ [key]: value });
    }

    // Update local state regardless of whether the value changed
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const skema = [
    { Kodi: "001", Pershkrim: "Mallra" },
    { Kodi: "002", Pershkrim: "Materiale te para" },
    { Kodi: "003", Pershkrim: "Materiale te tjera" },
    { Kodi: "004", Pershkrim: "Amballazh i kthyeshem" },
    { Kodi: "005", Pershkrim: "Inventari i Imet" },
    { Kodi: "006", Pershkrim: "Material ndihmes" },
    { Kodi: "007", Pershkrim: "Lende djegese" },
    { Kodi: "008", Pershkrim: "Pjese nderimi" },
    { Kodi: "009", Pershkrim: "Material ambalazhimi" },
    { Kodi: "010", Pershkrim: "Ndertesa" },
    {
      Kodi: "011",
      Pershkrim:
        "Instalime teknike, makineri, pajisje,instrumente dhe vegla pune",
    },
    { Kodi: "012", Pershkrim: "Mjete transporti" },
    { Kodi: "013", Pershkrim: "Mobilje dhe pajisje zyre" },
    {
      Kodi: "014",
      Pershkrim: "Koncensione dhe të drejta të ngjashme, licensa",
    },
    { Kodi: "015", Pershkrim: "Të tjera AA jomateriale" },
    { Kodi: "016", Pershkrim: "Pajisje informative" },
    { Kodi: "017", Pershkrim: "Te tjera" },
    { Kodi: "018", Pershkrim: "Toka" },
    { Kodi: "019", Pershkrim: "Sherbime" },
  ];

  const njesia = [
    { Kodi: "BIDO", Pershkrim: "BIDO" },
    { Kodi: "COP", Pershkrim: "Cope" },
    { Kodi: "KG", Pershkrim: "Kilogram" },
    { Kodi: "KOL", Pershkrim: "Koli" },
    { Kodi: "KT", Pershkrim: "Kuti" },
    { Kodi: "KV", Pershkrim: "Kuintal" },
    { Kodi: "LIT", Pershkrim: "Litra" },
    { Kodi: "M2", Pershkrim: "Metro Katrore" },
    { Kodi: "M3", Pershkrim: "Metro Kub" },
    { Kodi: "ML", Pershkrim: "Metro Lineare" },
    { Kodi: "ORE", Pershkrim: "Ore Pune" },
    { Kodi: "TON", Pershkrim: "Ton" },
    { Kodi: "TUF", Pershkrim: "tufa zorre 80-90m" },
  ];

  const numGrupi = `${grupi ? grupi.length : 0} Rekorde`;
  const numTarifa = `${tarifa ? tarifa.length : 0} Rekorde`;

  return (
    <div
      className={`first-border-pergjithshme flex ${
        window.innerWidth <= 768 ? "flex-col gap-4" : "gap-3"
      } border p-4`}
    >
      <div className="kutizat">
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Kodi || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Kodi: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Kodi", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          style={{ backgroundColor: "#F4CE14" }}
          label="Kodi"
          id="Kodi_"
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Kodi1 || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Kodi1: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Kodi1", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Kodi 1"
          id="Kodi_1"
        />
      </div>

      <div className="kutizat">
        <TextField
          value={state.Pershkrim || ""}
          InputLabelProps={{ shrink: true }}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Pershkrim: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Pershkrim", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Pershkrim"
          style={{ backgroundColor: "#F4CE14" }}
          id="Pershkrim"
        />

        <TextField
          value={state.Fjale_Kyce || ""}
          InputLabelProps={{ shrink: true }}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Fjale_Kyce: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Fjale_Kyce", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Fjale Kyce"
          id="Fjale_Kyce"
        />
      </div>

      <div className="kutizat">
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Barkod || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Barkod: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Barkod", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Barkod"
          id="Bar_Kodi"
          inputProps={{ maxLength: 12 }}
        />

        <TextField
          type="number"
          InputLabelProps={{ shrink: true }}
          value={state.Cmimi_Bleres || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Cmimi_Bleres: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Cmimi_Bleres", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Cmimi Bleres"
          id="Cmimi_Bleres"
        />
      </div>

      <div className="kutizat">
        <TextField
          type="number"
          InputLabelProps={{ shrink: true }}
          value={state.Cmimi || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Cmimi: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Cmimi", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Cmimi Shites"
          id="Cmimi_Shites"
        />

        <FormControl fullWidth>
          <InputLabel shrink={true} id="njesia-label">
            Njesia
          </InputLabel>
          <Select
            labelId="njesia-label"
            id="njesia-select"
            className="drop_down_list mb-2"
            disabled={disabled}
            size="small"
            value={state.Njesi_Kodi || ""}
            onChange={(e) => {
              handleChangeSelect("Njesi_Kodi", e.target.value);
            }}
            style={{ backgroundColor: "#fff" }}
          >
            <MenuItem style={{ display: "flex" }} value="">
              Clear
              <ClearIcon style={{ color: "red" }} />
            </MenuItem>

            {njesia?.map((item) => (
              <MenuItem key={item.Kodi} value={item.Kodi}>
                {item.Pershkrim}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="kutiza-fundit">
        <div className="drop_down_list">
          <FormControl fullWidth>
            <InputLabel shrink={true} id="grupi-label">
              Grupi
            </InputLabel>
            <Select
              labelId="grupi-label"
              id="grupi-select"
              disabled={disabled}
              size="small"
              className="drop_down_list"
              value={state?.Art_Kls01_Kodi || ""}
              onChange={(e) => {
                handleChangeSelect("Art_Kls01_Kodi", e.target.value);
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <MenuItem style={{ display: "flex" }} value="">
                Clear
                <ClearIcon style={{ color: "red" }} />
              </MenuItem>
              {grupi?.map((list) => (
                <MenuItem key={list?.Kodi} value={list?.Kodi}>
                  {list?.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <span
            style={{
              border: "solid 1px #ddd",
              backgroundColor: "#1971c2",
              color: "#fff",
            }}
          >
            <Grupi numGrupi={numGrupi} grupi={grupi} />
          </span>
        </div>

        <FormControl fullWidth>
          <InputLabel shrink={true} id="skema-label">
            Skema
          </InputLabel>
          <Select
            labelId="skema-label"
            id="skema-select"
            className="drop_down_list"
            disabled={disabled}
            style={{ backgroundColor: "#fff" }}
            size="small"
            value={state.Skema_Kodi || ""}
            onChange={(e) => {
              handleChangeSelect("Skema_Kodi", e.target.value);
            }}
          >
            <MenuItem style={{ display: "flex" }} value="">
              Clear
              <ClearIcon style={{ color: "red" }} />
            </MenuItem>
            {skema?.map((list) => (
              <MenuItem key={list.Kodi} value={list.Kodi}>
                {list.Pershkrim}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className="drop_down_list">
          <FormControl fullWidth>
            <InputLabel shrink={true} id="tarifa-label">
              Tarifa
            </InputLabel>
            <Select
              labelId="tarifa-label"
              id="tarifa-select"
              className="drop_down_list"
              style={{ backgroundColor: "#fff" }}
              disabled={disabled}
              size="small"
              value={state?.Tarifa_Kodi || ""}
              onChange={(e) => {
                const selectedValue = e.target.value;

                if (selectedValue === "") {
                  // If the clear option is selected
                  handleChangeTarifa("Tarifa_Kodi", "", { Tvsh: null }); // Clear the selection
                } else {
                  const selectedList = tarifa.find(
                    (list) => list.Kodi === selectedValue
                  );
                  handleChangeTarifa(
                    "Tarifa_Kodi",
                    selectedValue,
                    selectedList
                  );
                }
              }}
            >
              <MenuItem
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                value=""
              >
                Clear
                <ClearIcon style={{ color: "red", marginLeft: "8px" }} />
              </MenuItem>
              {tarifa?.map((list) => (
                <MenuItem key={list.Id} value={list.Kodi}>
                  {list.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <span
            style={{
              border: "solid 1px #ddd",
              backgroundColor: "#1971c2",
              color: "#fff",
            }}
          >
            {/* Assuming Tarifat is a component that displays numTarifa */}
            <Tarifat numTarifa={numTarifa} tarifa={tarifa} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TextFieldsTePergjithshme;
