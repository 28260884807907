import React, { createContext, useContext, useState, useEffect } from "react";

// Create a context
const MyContext = createContext();

// Create a provider for the context
export const MyProvider = ({ children }) => {
  // Get the date part of the current date
  const currentDate = new Date().toISOString().split("T")[0];

  // Get formatted date and time
  const formattedDateTime = new Date().toISOString();
  const [operator, setOperator] = useState("");
  const [nipt, setNipt] = useState("");
  const [dtlState, setDtlState] = useState([]);
  const [currentAction, setCurrentAction] = useState("");
  const [loadingLeftBar, setLoadingLeftBar] = useState(false);
  const [dtlNum, setDtlNum] = useState(dtlState.length);
  const [gjeneroFatureAnulluese, setGjeneroFatureAnulluese] = useState(false);
  const [open, setOpen] = useState(false);
  const [aExistTable, setTableExist] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // let defaultState = {
  //   Aktiv: 1,
  //   E_Paguar: 0,
  //   Kodi: 1,
  //   Menyra_Pageses_Id: 6,
  //   Pike_Fitim: 0,
  //   Tip_Cmimi: "CMIMI",
  //   Operator: `${operator}`,
  //   Tipi: "FTSH",
  //   Nipt: `${nipt}`,
  //   KLFU_Pershkrim: "",
  //   KLFU_Kodi: "",
  // };

  let defaultState = {
    Aktiv: 1,
    Operator: String(operator),
    Nipt: String(nipt),
    E_Paguar: 0,
    // Kodi: 1,
    Hapur: formattedDateTime,
    Data: `${currentDate}T00:00:00`,
    KLFU_Pershkrim: "",
    Menyra_Pageses_Id: 6,
    Pike_Fitim: 0,
    Tavolina: null,
    Tip_Cmimi: "CMIMI",
    Tipi: "FTSH",
  };

  const [state, setState] = useState(defaultState);

  // Define fetchData function
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setOperator(parsedData.username);
      setNipt(parsedData.nipt);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update the state when operator changes
    setState((prevState) => ({
      ...prevState,
      Operator: operator,
    }));
  }, [operator]);

  // New useEffect to update dtlNum whenever dtlState changes
  useEffect(() => {
    setDtlNum(dtlState.length); // Update dtlNum when dtlState changes
  }, [dtlState]);

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <MyContext.Provider
      value={{
        state,
        setState,
        currentAction,
        setCurrentAction,
        updateState,
        dtlState,
        setDtlState,
        loadingLeftBar,
        setLoadingLeftBar,
        open,
        setOpen,
        aExistTable,
        setTableExist,
        gjeneroFatureAnulluese,
        setGjeneroFatureAnulluese,
        dtlNum,
        setDtlNum,
        defaultState,
        currentPage,
        setCurrentPage,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

// Custom hook to use the context
export const useMyContext = () => useContext(MyContext);
