import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import LeftBarPrint from "../Main/LeftBar/LeftBarPrint";
import PrintoPdf1 from "../../../../components/ReactPDF/PrintoPdf1";
import IconShopping from "./IconShopping";
import SplitButton from "../Main/LeftBar/SplitButtons/Button";
import "../Main/MainComponent/MainComponent.scss";
import LeftBar from "../Main/LeftBar/LeftBar";
import ShtoKlient from "../Main/ShtoKlient/ShtoKlient";
import PrintoFisPos from "../../../../components/ReactPDF/PrintoFisPos";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SideBarPos({
  setClickedItems,
  numTav,
  newId,
  rows,
  totalCmimi,
  menyra,
  setCurrentPorosia,
  state,
  faturaData,
  // shikoTotalin,
  // totaliFatura,
  printPorosia,
  isMobile,
  clickedItems,
  toggleDataTable,
  isDataTableOpen,
  showLoading,
  hideLoading,
  messageDisplay,
  aExistTable,
  onItemClick,
  konfigurimeRedux,
  posBar,
  mbylleTav,
  fatureThjesht,
  setPrintPorosia,
  className,
  operator,
  nipt,
  showToast,
  handleFiskalizim,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button color="inherit" variant="outlined" onClick={handleClickOpen}>
        <IconShopping clickedItems={clickedItems} />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div
          className="sidebar_phone_main"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#ececec",
          }}
        >
          <AppBar
            sx={{
              position: "relative",
              ...(isMobile && {
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }),
            }}
          >
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Tavolina: {numTav}
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div
            style={{
              // marginTop: "20px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LeftBar
              state={state}
              // shikoTotalin={shikoTotalin}
              // totaliFatura={totaliFatura}
              printPorosia={printPorosia}
              setCurrentPorosia={setCurrentPorosia}
              isMobile={isMobile}
              clickedItems={clickedItems}
              onItemClick={onItemClick}
              setClickedItems={setClickedItems}
              toggleDataTable={toggleDataTable}
              isDataTableOpen={isDataTableOpen}
              showLoading={showLoading}
              hideLoading={hideLoading}
              messageDisplay={messageDisplay}
              aExistTable={aExistTable}
            />
            {/* <LeftBarPrint
              clickedItems={clickedItems}
              onItemClick={onItemClick}
              setClickedItems={setClickedItems}
              setClickedNewItems={setClickedNewItems}
              setCurrentPorosia={setCurrentPorosia}
            /> */}
          </div>
          <div className="footer_phone_main">
            <hr className="hr_pos" />
            <div
              className="total_amount flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="total">
                <h1>Total</h1>
              </div>
              <div className="amount">
                <h2>$ {totalCmimi}</h2>
              </div>
            </div>
            <div
              className="background_button flex"
              style={{ justifyContent: "space-between" }}
            >
              <PrintoPdf1
                konfigurimeRedux={konfigurimeRedux}
                handleClose={handleClose}
                posBar={true}
                mbylleTav={mbylleTav}
                fatureThjesht={true}
                setPrintPorosia={setPrintPorosia}
                className="print"
                rows={rows}
                state={state}
                operator={operator}
                menyrapageses={menyra}
                nipt={nipt}
                showToast={showToast}
              />

              <ShtoKlient />

              <PrintoFisPos
                pos={true}
                handleFiskalizim={handleFiskalizim}
                rows={rows}
                state={faturaData}
                operator={operator}
                menyra={menyra}
                konfigurimeRedux={konfigurimeRedux}
                nipt={nipt}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
