// import React, { useState, useEffect } from "react";
// import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import PeopleIcon from "@mui/icons-material/People";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import TextField from "@mui/material/TextField";
// import mainAxios from "../../../../../services/axios";
// import { useMyContext } from "../../../../../components/context/MyStatePOSContext";

// export default function ShtoKlient({ klienti }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const { state, updateState } = useMyContext();

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <IconButton onClick={handleClick} style={{ fontSize: "2rem" }}>
//         <Badge badgeContent={state.KLFU_Pershkrim} color="primary">
//           <PeopleIcon style={{ color: "#fff", fontSize: "2rem" }} />
//         </Badge>
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//         PaperProps={{
//           style: {
//             maxHeight: 500,
//             width: "20ch",
//             top: "50px",
//           },
//         }}
//         MenuListProps={{ style: { paddingTop: 0 } }}
//       >
//         <div style={{ padding: "8px 16px", borderBottom: "1px solid #ddd" }}>
//           <TextField
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             label="Search"
//             variant="outlined"
//             size="small"
//             fullWidth
//           />
//         </div>
//         <div style={{ maxHeight: 400, overflowY: "auto" }}>
//           {filteredKlienti.map((klient) => (
//             <MenuItem
//               key={klient.Kodi}
//               onClick={() => handleSelectChange(klient)}
//               style={{ fontSize: "0.9rem", padding: "4px 16px" }}
//             >
//               {klient.Pershkrim}
//             </MenuItem>
//           ))}
//         </div>
//       </Menu>
//     </div>
//   );
// }

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import PeopleIcon from "@mui/icons-material/People";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import mainAxios from "../../../../../services/axios";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";

export default function ShtoKlient({ klienti }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { state, updateState } = useMyContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectChange = async (value) => {
    if (value) {
      const postDataWithoutId = {
        KLFU_Kodi: value.Kodi,
        KLFU_Pershkrim: value.Pershkrim,
      };
      try {
        await mainAxios.put(`/pos/${state.Id}`, postDataWithoutId);
        updateState({
          KLFU_Kodi: value.Kodi,
          KLFU_Pershkrim: value.Pershkrim,
        });
      } catch (error) {
        console.error("There was an error updating the data!", error);
      }
    }
    handleClose();
  };

  const filteredKlienti = klienti.filter((klient) =>
    klient.Pershkrim.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ position: "relative" }}
        >
          <div
            style={{
              fontSize: "10px",
              backgroundColor: "white",
              borderRadius: "10%",
              position: "absolute",
              top: "-25px",
              left: "25px",
              color: "black",
            }}
          >
            {state.KLFU_Pershkrim}
          </div>
          <PeopleIcon style={{ color: "#fff", fontSize: "40px" }} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <TextField
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    {filteredKlienti.map((klient) => (
                      <MenuItem
                        key={klient.Kodi}
                        onClick={() => handleSelectChange(klient)}
                        style={{ fontSize: "0.9rem", padding: "4px 16px" }}
                      >
                        {klient.Pershkrim}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
