import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import getColor from "../VariableColors/GetColor";
import "./MainBotCom.scss";
import noDataicon from "../../../../../assets/no-data.png";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";
import { getArtikujPos } from "../../../../../store/globalPos";
import { useDispatch } from "react-redux";
import { useArtContext } from "../../../../../components/context/ArtikullContextPosBar";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";

const MainBotComponent = ({ onItemClick, fromSearch }) => {
  const { gjeneroFatureAnulluese, currentPage, setCurrentPage } =
    useMyContext();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const { artData, setArtData, setFromKthePanelet } = useArtContext();
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const ColoredItem = styled(Paper)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "70px",
    cursor: "pointer",
  }));

  const ColorIndicator = styled("div")(({ theme, pershkrimcolor }) => ({
    position: "absolute",
    top: 0,
    left: "2%",
    width: "2%",
    height: "90%",
    borderRadius: "15%",
    overflow: "hidden",
    backgroundColor: getColor(theme, pershkrimcolor),
  }));

  function FormRow({ data, theme }) {
    return (
      <React.Fragment>
        {data.map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={item.Kodi}
            onClick={() => onItemClick(item)}
          >
            <ColoredItem theme={theme} pershkrimcolor={item.Pershkrim}>
              <ColorIndicator theme={theme} pershkrimcolor={item.Pershkrim} />
              <div className="inside_Art">
                <div className="PershrkimArt">{item.Pershkrim}</div>
                <div className="CmimiArt">{item.Cmimi} Lek</div>
                <div>{item.Njesi_Kodi}</div>
              </div>
            </ColoredItem>
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  const theme = {};

  const handleScroll = async () => {
    if (fromSearch) return; // Block scrolling when fromSearch is true
    setFromKthePanelet(false);
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [fromSearch]); // Re-attach listener if fromSearch changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        const response = await dispatch(
          getArtikujPos(`/pos/art?page=${currentPage}&page_size=50`)
        );

        // Check if the response has the data in the expected format
        if (
          response &&
          response.payload &&
          Array.isArray(response.payload.data)
        ) {
          // Update the artData state by appending the new data to the previous state
          setArtData((prevArtData) => {
            // Combine previous artData with new data
            const newArtData = response.payload.data;
            // Filter out duplicates if needed based on a unique identifier (e.g., item.Kodi)
            const combinedData = [...prevArtData, ...newArtData];

            // Optionally remove duplicates by using a Set or some other method
            const uniqueData = Array.from(
              new Map(combinedData.map((item) => [item.Kodi, item])).values()
            );

            return uniqueData; // Return unique data
          });
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        hideLoading();
      }
    };

    fetchData();
  }, [currentPage, dispatch]);

  return (
    <div
      className="mainBot"
      ref={containerRef} // Attach the ref to the container
      style={{
        height: "100%",
        overflowY: "auto", // Allow vertical scrolling
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {!gjeneroFatureAnulluese && (
          <Grid container spacing={1}>
            {artData.length === 0 && (
              <div
                style={{ textAlign: "center", padding: "10px", color: "gray" }}
              >
                <div className="flex direction-column">
                  <img
                    src={noDataicon}
                    alt="No data"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <p>Nuk u gjend asnje artikull.</p>
                </div>
              </div>
            )}
            {Array.from({ length: Math.ceil(artData.length / 4) }).map(
              (_, rowIndex) => (
                <Grid container item spacing={3} key={rowIndex}>
                  <FormRow
                    theme={theme}
                    data={artData.slice(rowIndex * 4, rowIndex * 4 + 4)}
                  />
                </Grid>
              )
            )}
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default MainBotComponent;
