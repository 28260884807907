import mainAxios from "../../../../../services/axios";

const handleFiskalizim = async (
  handlePrint2,
  {
    rows,
    formatDate,
    state,
    setState,
    defaultState,
    fromRegister,
    dtlState,
    currentID,
    showLoading,
    hideLoading,
    showToast,
    setDtlState,
    setOpen,
    mbylleTav,
    deletedRowIds,
    setDeletedRowIds,
  }
) => {
  if (rows.length === 0) {
    const message = `Nuk keni asnje artikull ne fature !`;
    showToast(message, { severity: "warn" });
    return;
  }

  const updatedItems = dtlState.map((item) => {
    let tvsh = (item.Tvsh + 100) / 100;
    const skontoVlera = (item.Sasia * (item.Cmimi / tvsh) * item.Skonto) / 100;
    const vlera = item.Sasia * item.Cmimi;
    const vleraPaTvsh = item.Sasia * (item.Cmimi / tvsh) - skontoVlera;
    const tvshVlera = vlera - vleraPaTvsh;

    return {
      Master_Id: currentID,
      Pershkrim: item.Pershkrim,
      Sasia: item.Sasia.toFixed(2),
      Tvsh: item.Tvsh,
      Vlera: vlera.toFixed(2),
      Vlera_Pa_Tvsh: vleraPaTvsh.toFixed(2),
      Cmimi: item.Cmimi.toFixed(2),
      Tvsh_Vlera: tvshVlera.toFixed(2),
      Printuar: 1,
    };
  });

  // Sending a single PUT request for all updated items
  const response = await mainAxios.post(`/pos/dtl/bulk`, updatedItems);

  if (response.status === 200) {
    showLoading();
    const { ...putAfterFisError } = {
      Aktiv: 1,
      Mbyllur: null,
      E_Paguar: 0,
    };

    const { ...putBeforeFis } = {
      Aktiv: 0,
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };

    try {
      const res = await mainAxios.put(`/pos/${currentID}`, putBeforeFis, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.status === 200) {
        try {
          const response = await mainAxios.post(`/pos/${currentID}`);
          const firstItem = response.data;

          if (firstItem.ErrorCode !== "0") {
            await mainAxios.put(`/pos/${currentID}`, putAfterFisError, {
              headers: { "Content-Type": "application/json" },
            });

            const faultstring = firstItem.faultstring;
            showToast(faultstring, { severity: "error" });

            return;
          } else {
            const response = await mainAxios(`/pos/${currentID}`);
            await setState(response.data);
            handlePrint2();
          }
        } catch (error) {
          console.error("Error fiskalizimi:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setOpen(false);
      setState(defaultState);
      hideLoading();
    }
  }
};

export { handleFiskalizim };
