import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Create a context
const ArtContext = createContext();

// Create a provider for the context
export const MyProviderArt = ({ children }) => {
  // redux artikuj (get requesti i artikujve)
  const artikujPos = useSelector((state) => state.globalPosSlice.artikujPos);
  const [artData, setArtData] = useState(artikujPos);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [originalArtData, setOriginalArtData] = useState(artikujPos);
  const [fromKthePanelet, setFromKthePanelet] = useState(true);
  useEffect(() => {
    // Set original art data when artikujPos changes
    if (artikujPos.length > 0) {
      if (fromKthePanelet) {
        setArtData((prevArtData) => [...artikujPos, ...prevArtData]);
      } else {
        setArtData((prevArtData) => [...prevArtData, ...artikujPos]);
      }

      setOriginalArtData(artikujPos); // Update original data
      setNoDataMessage("");
    } else {
      setNoDataMessage("Nuk u gjend asnje artikull.");
    }
  }, [artikujPos]);

  return (
    <ArtContext.Provider
      value={{
        artData,
        setArtData,
        artikujPos,
        noDataMessage,
        setNoDataMessage,
        originalArtData,
        setFromKthePanelet,
      }}
    >
      {children}
    </ArtContext.Provider>
  );
};

// Custom hook to use the context
export const useArtContext = () => useContext(ArtContext);
