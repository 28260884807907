import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import SideBarPos from "../SideBarPos/SideBarPos";

export default function BottomAppBar({
  handleSearchChange,
  searchQuery,
  handleToggleLeftBarPrint,
  setSearchQuery,
  clickedItems,
  faturaData,
  onItemClick,
  setClickedItems,
  setClickedNewItems,
  state,
  numTav,
  newId,
  totalCmimi,
  rows,
  menyra,
  setCurrentPorosia,
  // shikoTotalin,
  // totaliFatura,
  printPorosia,
  isMobile,
  toggleDataTable,
  mbylleTav,
  isDataTableOpen,
  showLoading,
  hideLoading,
  messageDisplay,
  aExistTable,
}) {
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer">
            <SideBarPos
              rows={rows}
              setCurrentPorosia={setCurrentPorosia}
              handleSearchChange={handleSearchChange}
              handleToggleLeftBarPrint={handleToggleLeftBarPrint}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              clickedItems={clickedItems}
              onItemClick={onItemClick}
              setClickedItems={setClickedItems}
              setClickedNewItems={setClickedNewItems}
              state={state}
              numTav={numTav}
              newId={newId}
              totalCmimi={totalCmimi}
              menyra={menyra}
              // shikoTotalin={shikoTotalin}
              faturaData={faturaData}
              // totaliFatura={totaliFatura}
              printPorosia={printPorosia}
              isMobile={isMobile}
              toggleDataTable={toggleDataTable}
              isDataTableOpen={isDataTableOpen}
              showLoading={showLoading}
              mbylleTav={mbylleTav}
              hideLoading={hideLoading}
              messageDisplay={messageDisplay}
              aExistTable={aExistTable}
            />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <InputBase
            sx={{ ml: 1, flex: 1, color: "#fff" }}
            placeholder="Kerko Artikullin"
            inputProps={{ "aria-label": "Kerko Artikullin" }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <IconButton color="inherit" onClick={handleToggleLeftBarPrint}>
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
