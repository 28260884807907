import React, { useEffect, useState, memo, useContext, useRef } from "react";
import PrimeGrid from "../../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import mainAxios from "../../../services/axios";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import MainTabArka from "../../fature-blerje/tabet/MainTabArka/MainTabArka";
import PaneliTabeve from "../../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../../fature-blerje/tabet/PaneliTab2";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import PrintoPdf1 from "../../components/ReactPDF/PrintoPdf1";
import "../../fature-blerje/FatureBlerje.scss";
import { Toast } from "primereact/toast";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import Footer from "../../../components/Navbar/Footer";
import Template from "../../dynamicComponent/Template";
import {
  shtoArtikull,
  calculateData,
} from "../../dynamicComponent/FleteArtCalc";
import ConfirmationDialog from "../../dynamicComponent/ConfirmationDialog";
import { useLocation } from "react-router-dom";
import { getIndexFirstAr } from "../../../store/globalSlice";
import { useDispatch } from "react-redux";
import TabTjera from "../../fature-blerje/tabet/Tjera/TabTjera";
import TabKlienti from "../../fature-blerje/tabet/Klienti/TabKlienti";
import Check from "../../fature-blerje/tabet/CheckArka/Check";
import TabKLS from "../../fature-blerje/tabet/KLS/TabKLS";
import TabFurnitore from "../../fature-blerje/tabet/Furnitor/TabFurnitore";
import MiniArka from "../../fature-blerje/minimodal/Arka";
import MiniBanka from "../../fature-blerje/minimodal/Banka";

const Banka = ({
  currentAction,
  setCurrentAction,
  isOnline,
  isMobile,
  disabled,
  setDisabled,
}) => {
  const dispatch = useDispatch();
  // const { globalSmallApi } = useGlobalSmallApi();
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [hidePupUp, setHidePupUp] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const location = useLocation();
  const [searchString, setSearchString] = useState("");
  const [gjendjeArke, setGjendje] = useState([]);
  const [pershkrimiArkes, setPershkrimiArkes] = useState("");
  const { nmrFB, fromFatureBlerje } = location.state || {};

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  // Define fetchData outside the useEffect
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // qyteti redux
  const qytetetRedux = useSelector((state) => state.globalSlice.qytetet);
  const qytetet = qytetetRedux;

  // shteti redux
  const shtetetRedux = useSelector((state) => state.globalSlice.shtetet);
  const shtetet = shtetetRedux.map((item) => item.Kodi);

  // redux klienti (get requesti i klientit)
  const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const klienti = klientiRedux;

  const furnitorRedux = useSelector((state) => state.globalSlice.furnitor);
  const furnitori = furnitorRedux;

  // redux banka/banka (get requesti i Banka/Banka)
  const banka = useSelector((state) => state.globalSlice.arkabanka);
  // Filter the `banka` array where `Kodi` is "A09"

  // redux mer indexin e fatures se pare
  const firstFleteHIndex = useSelector((state) => state.globalSlice.getFirstAr);

  // redux skema-veprimi (get requesti i veprimit)
  const veprimiRedux = useSelector((state) => state.globalSlice.veprimi);

  // Filter veprimiRedux based on Tabela value
  const bankaObjects = veprimiRedux.filter((item) => item.Tabela === "BANKA");

  const veprimi = bankaObjects.map((item) => item.Kodi);

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = () => {
    setCurrentAction("add");
    setCurrentId(lastId);
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    if (state.Fature_Blerje_Id !== null) {
      setShowConfirmation(true); // Show the confirmation dialog
    } else {
      // setFiskalDisable(true)
      setCurrentAction("modify");
      setDisabled(false);
      setButtonClicked(true);
    }
  };

  // Function to handle confirmation
  const handleConfirmation = (confirm) => {
    setShowConfirmation(false); // Hide the confirmation dialog
    if (confirm) {
      setDisabled(false);
      setButtonClicked(true);
      // setFiskalDisable(true)
      setCurrentAction("modify");
    }
  };

  const [rows, setRows] = useState([]);

  const [index, setIndex] = useState(0);

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Shenim", title: "Shenim" },
    { field: "Mon", title: "Mon" },
    { field: "Kursi", title: "Kursi" },
    { field: "Debi", title: "Debi" },
    { field: "Kredi", title: "Kredi" },
    { field: "Total", title: "Total" },
    { field: "Llogari", title: "Llogari" },
    { field: "Tipi", title: "Tipi" },
    { field: "Origjina", title: "Origjina" },
    { field: "Vlera", title: "Vlera" },
    { field: "Org_Tipi", title: "Org_Tipi" },
    { field: "Departament", title: "Departament" },
    { field: "Magazina", title: "Magazina" },
    { field: "Klasifikim1", title: "Klasifikim1" },
    { field: "Klasifikim2", title: "Klasifikim2" },
    { field: "Klasifikim3", title: "Klasifikim3" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };

  let defaultState = {
    Kodi: "",
    NrSerik: "",
    Fature_Shitje_Id: "",
    Fature_Blerje_Id: "",
    Mon: "",
    Kursi: "",
    Vlera: "",
    Total: "",
    Tipi: "",
    Veprimi: veprimi[5],
    KLFU_Kodi: "",
    KLFU_Pershkrim: "",
    Nipt: "",
    Dep_Kodi: "",
    Data: "",
    ReferencaShenim: "",
    Shenim: "",
    Menyra_Pageses_Id: "",
    Banka_List_Kodi: "",
    NrLL: "",
    Punonjes_Id: "",
    Operator: String(username),
    Updated: "",
    Aktiv: "",
    Selektuar: "",
    Mbyllur: "",
    KLFU_Tipi: "",
    Check_Eshte: "",
    Check_Kodi: "",
    Check_Banka_Kodi: "",
    Check_Banka_Pershkrim: "",
    Check_Data: "",
    Check_RoutingNumber: "",
    Check_AccountNumber: "",
    Check_Emri: "",
    Check_Mbiemri: "",
    Magazina_Kodi: "",
    Klasifikim1_ID: "",
    Klasifikim2_ID: "",
    Klasifikim3_ID: "",
    Klasifikim4_ID: "",
    PeriudhaPageses: "",
    Fatura: "",
    Fugon: "",
    OnTheWay: "",
    OperatorConfirmation: "",
    DateConfirmation: "",
    Confirmed: "",
    StatusColor: "",
    inserted: "",
  };

  const [state, setState] = useState(defaultState);
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);
  const [number, setNumber] = useState(1);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);

  useEffect(() => {
    if (fromFatureBlerje) {
      setNumber(nmrFB);
      fetchBanka(nmrFB);
    } else {
      fetchBanka(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDepKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Dep_Kodi: newKodi,
    }));
  };

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchBanka = async (number) => {
    try {
      showLoading();
      const response = await mainAxios(`/last/banka/${number}`);

      const responseTotalRows = await dispatch(getIndexFirstAr("/first/ba"));
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      handleChangeValue(response.data.Id);

      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      const responseData = response.data;
      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(`/banka/dtl/${responseData.Id}`);
        const dtlData = dtlResponse.data;

        // Filter the `banka` array where `Kodi` is "A09"
        const filteredArka = banka.filter(
          (item) => item.Kodi === `${responseData.Banka_List_Kodi}`
        );

        const fetchGjendje = await mainAxios(
          `/banka/kodi/${responseData.Banka_List_Kodi}`
        );
        const gjendjaArkes = fetchGjendje.data.Gjendje;

        setPershkrimiArkes(filteredArka[0].Pershkrim);
        setGjendje(gjendjaArkes);
        setState(responseData);
        setResponseData(responseData);
        setLastObject(responseData);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = async (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);

    if (type === "first") {
      setNumber(firstFleteHIndex.total_rows);
      fetchBanka(firstFleteHIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchBanka(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchBanka(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchBanka(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  // Toast Notification dynamic
  const toast = useRef(null);
  // Toast Notification dynamic
  const showToast = (message, options = {}) => {
    toast.current.show({
      severity: options.severity || "info",
      summary: message,
      position: "top-center",
      detail: options.detail || "",
      life: options.life || 2000,
      closable: options.closable,
      ...options,
    });
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(`/banka/index/${newTesti.Id}`);

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchBanka(numberIndex);

        showToast("Banka u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e Bankes.", { severity: "error" });

      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/banka/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleAdd();
      setCurrentAction(null);
    } else if (currentAction === "modify") {
      handleModify();
      setCurrentAction(null);
    }
  };

  // Kur Klikohet butoni i "SHTIM"
  const handleAdd = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Debi: row.Debi,
          Dep_Kodi: row.Dep_Kodi,
          IdOld: row.IdOld,
          Inserted: row.Inserted,
          Klasifikim1_ID: row.Klasifikim1_ID,
          Klasifikim2_ID: row.Klasifikim2_ID,
          Klasifikim3_ID: row.Klasifikim3_ID,
          Klasifikim4_ID: row.Klasifikim4_ID,
          Kodi: row.Kodi,
          Kredi: row.Kredi,
          Kursi: row.Kursi,
          Magazina_Kodi: row.Magazina_Kodi,
          Mon: row.Mon,
          NrLL: row.NrLL,
          NrLL_Default: row.NrLL_Default,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Org_Id: row.Org_Id,
          Org_Kodi: row.Org_Kodi,
          Org_Tipi: row.Org_Tipi,
          Pershkrim: row.Pershkrim,
          Selektuar: row.Selektuar,
          Shenim: row.Shenim,
          Tipi: row.Tipi,
          Total: row.Total,
          Updated: row.Updated,
        };
        return item;
      });
    };
    try {
      showLoading();
      //  POST request i 1 per FATURE SHITJE
      const response = await mainAxios.post(`/banka`, {
        Kodi: state.Kodi,
        NrSerik: state.NrSerik,
        Fature_Shitje_Id: state.Fature_Shitje_Id,
        Fature_Blerje_Id: state.Fature_Blerje_Id,
        Mon: state.Mon,
        Kursi: state.Kursi,
        Vlera: state.Vlera,
        Total: state.Total,
        Tipi: state.Tipi,
        Veprimi: state.Veprimi,
        KLFU_Kodi: state.KLFU_Kodi,
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Nipt: state.Nipt,
        Dep_Kodi: state.Dep_Kodi,
        Data: formatDate(state.Data, true),
        ReferencaShenim: state.ReferencaShenim,
        Shenim: state.Shenim,
        Menyra_Pageses_Id: state.Menyra_Pageses_Id,
        Banka_List_Kodi: state.Banka_List_Kodi,
        NrLL: state.NrLL,
        Punonjes_Id: state.Punonjes_Id,
        Operator: String(username),
        Updated: formatDate(state.Updated),
        Aktiv: Number(state.Aktiv),
        Selektuar: Number(state.Selektuar),
        Mbyllur: state.Mbyllur,
        KLFU_Tipi: state.KLFU_Tipi,
        Check_Eshte: state.Check_Eshte,
        Check_Kodi: state.Check_Kodi,
        Check_Banka_Kodi: state.Check_Banka_Kodi,
        Check_Banka_Pershkrim: state.Check_Banka_Pershkrim,
        Check_Data: state.Check_Data,
        Check_RoutingNumber: state.Check_RoutingNumber,
        Check_AccountNumber: state.Check_AccountNumber,
        Check_Emri: state.Check_Emri,
        Check_Mbiemri: state.Check_Mbiemri,
        Magazina_Kodi: state.Magazina_Kodi,
        Klasifikim1_ID: state.Klasifikim1_ID,
        Klasifikim2_ID: state.Klasifikim2_ID,
        Klasifikim3_ID: state.Klasifikim3_ID,
        Klasifikim4_ID: state.Klasifikim4_ID,
        PeriudhaPageses: formatDate(state.PeriudhaPageses, true),
        Fatura: Number(state.Fatura),
        Fugon: Number(state.Fugon),
        OnTheWay: state.OnTheWay,
        OperatorConfirmation: state.OperatorConfirmation,
        DateConfirmation: state.DateConfirmation,
        Confirmed: state.Confirmed,
        StatusColor: state.StatusColor,
        inserted: state.inserted,
      });
      if (response.status === 201) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        toast.current.show({
          severity: "success",
          summary: "Banka u rregjistrua.",
        });

        // POST request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.post(`/banka/dtl/`, dtlDataArray);
        setCurrentAction(null);
        fetchBanka(1);
      }
    } catch (error) {
      console.error("Error in POST request:", error);

      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë regjistrimit të faturës.",
      });
    } finally {
      // fetchDtl();
      hideLoading();
    }
  };

  // Kur Klikohet butoni i "MODIFIKO"
  const handleModify = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Debi: row.Debi,
          Dep_Kodi: row.Dep_Kodi,
          IdOld: row.IdOld,
          Inserted: row.Inserted,
          Klasifikim1_ID: row.Klasifikim1_ID,
          Klasifikim2_ID: row.Klasifikim2_ID,
          Klasifikim3_ID: row.Klasifikim3_ID,
          Klasifikim4_ID: row.Klasifikim4_ID,
          Kodi: row.Kodi,
          Kredi: row.Kredi,
          Kursi: row.Kursi,
          Magazina_Kodi: row.Magazina_Kodi,
          Mon: row.Mon,
          NrLL: row.NrLL,
          NrLL_Default: row.NrLL_Default,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Org_Id: row.Org_Id,
          Org_Kodi: row.Org_Kodi,
          Org_Tipi: row.Org_Tipi,
          Pershkrim: row.Pershkrim,
          Selektuar: row.Selektuar,
          Shenim: row.Shenim,
          Tipi: row.Tipi,
          Total: row.Total,
          Updated: row.Updated,
        };
        return item;
      });
    };
    try {
      showLoading();
      // Requesti i 1 PUT per Arkan
      const response = await mainAxios.put(`/banka/${currentId}`, {
        Kodi: state.Kodi,
        NrSerik: state.NrSerik,
        Fature_Shitje_Id: state.Fature_Shitje_Id,
        Fature_Blerje_Id: state.Fature_Blerje_Id,
        Mon: state.Mon,
        Kursi: state.Kursi,
        Vlera: state.Vlera,
        Total: state.Total,
        Tipi: state.Tipi,
        Veprimi: state.Veprimi,
        KLFU_Kodi: state.KLFU_Kodi,
        KLFU_Pershkrim: state.KLFU_Pershkrim,
        Nipt: state.Nipt,
        Dep_Kodi: state.Dep_Kodi,
        Data: formatDate(state.Data, true),
        ReferencaShenim: state.ReferencaShenim,
        Shenim: state.Shenim,
        Menyra_Pageses_Id: state.Menyra_Pageses_Id,
        Banka_List_Kodi: state.Banka_List_Kodi,
        NrLL: state.NrLL,
        Punonjes_Id: state.Punonjes_Id,
        Operator: String(username),
        Updated: formatDate(state.Updated, true),
        Aktiv: Number(state.Aktiv),
        Selektuar: Number(state.Selektuar),
        Mbyllur: state.Mbyllur,
        KLFU_Tipi: state.KLFU_Tipi,
        Check_Eshte: state.Check_Eshte,
        Check_Kodi: state.Check_Kodi,
        Check_Banka_Kodi: state.Check_Banka_Kodi,
        Check_Banka_Pershkrim: state.Check_Banka_Pershkrim,
        Check_Data: state.Check_Data,
        Check_RoutingNumber: state.Check_RoutingNumber,
        Check_AccountNumber: state.Check_AccountNumber,
        Check_Emri: state.Check_Emri,
        Check_Mbiemri: state.Check_Mbiemri,
        Magazina_Kodi: state.Magazina_Kodi,
        Klasifikim1_ID: state.Klasifikim1_ID,
        Klasifikim2_ID: state.Klasifikim2_ID,
        Klasifikim3_ID: state.Klasifikim3_ID,
        Klasifikim4_ID: state.Klasifikim4_ID,
        PeriudhaPageses: formatDate(state.PeriudhaPageses, true),
        Fatura: Number(state.Fatura),
        Fugon: Number(state.Fugon),
        OnTheWay: state.OnTheWay,
        OperatorConfirmation: state.OperatorConfirmation,
        DateConfirmation: state.DateConfirmation,
        Confirmed: state.Confirmed,
        StatusColor: state.StatusColor,
        inserted: state.inserted,
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        toast.current.show({
          severity: "success",
          summary: "Banka u modifikua!",
        });

        //  PUT request i 2 per modifikimin e DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.put(`/banka/dtl/`, dtlDataArray);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Gabim gjatë modifikimit të artikujve.",
        });
      }
    } catch (error) {
      console.error("Error in POST request:", error);

      toast.current.show({
        severity: "error",
        summary: "Gabim gjatë modifikimit të Arkas.",
      });
    } finally {
      fetchBanka(number);
      hideLoading();
      setCurrentAction(null);
    }
  };

  //Ploteson fushat e tjera nqs Zgjedh nje X-klient
  //Dmth nqs zgjedh F003 => NIPT (L61918004H)
  //F003 => klient (Ahmetaj )
  const handleKlientiType = async (e, value) => {
    setSearchString(value ? value.Kodi : "");
    if (value && value.Kodi) {
      try {
        const selectedKlienti = klientiRedux.find(
          (item) => item.Kodi === value.Kodi
        );

        if (selectedKlienti) {
          setState((state) => ({
            ...state,
            KLFU_Kodi: selectedKlienti.Kodi,
            KLFU_Pershkrim: selectedKlienti.Pershkrim,
            NIPT: selectedKlienti.Nipt,
            Qyteti_Kodi: selectedKlienti.Qyteti_Kodi,
          }));
        } else {
          // If selectedKlienti is not found, clear the text fields
          setState((state) => ({
            ...state,
            KLFU_Kodi: value,
            KLFU_Pershkrim: "",
            NIPT: "",
            Qyteti_Kodi: "",
          }));
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      // If value is empty or value.Kodi is falsy, clear all fields
      setState((state) => ({
        ...state,
        KLFU_Kodi: "",
        KLFU_Pershkrim: "",
        NIPT: "",
        Qyteti_Kodi: "",
      }));
    }
  };

  //Ploteson fushat e tjera nqs Zgjedh nje X-Furnitor
  //Dmth nqs zgjedh F003 => Nipt (L61918004H)
  //F003 => Furnitor (Ahmetaj )

  const handleFurnitorType = async (e, value) => {
    setSearchString(value ? value.Kodi : "");
    if (value && value.Kodi) {
      try {
        const selectedFurnitor = furnitorRedux.find(
          (item) => item.Kodi === value.Kodi
        );

        if (selectedFurnitor) {
          setState((state) => ({
            ...state,
            KLFU_Kodi: selectedFurnitor.Kodi,
            KLFU_Pershkrim: selectedFurnitor.Pershkrim,
            NIPT: selectedFurnitor.Nipt,
            Qyteti_Kodi: selectedFurnitor.Qyteti_Kodi,
          }));
        } else {
          // If selectedFurnitor is not found, clear the text fields
          setState((state) => ({
            ...state,
            KLFU_Kodi: value,
            KLFU_Pershkrim: "",
            NIPT: "",
            Qyteti_Kodi: "",
          }));
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      // If value is empty or value.Kodi is falsy, clear all fields
      setState((state) => ({
        ...state,
        KLFU_Kodi: "",
        KLFU_Pershkrim: "",
        NIPT: "",
        Qyteti_Kodi: "",
      }));
    }
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;

    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      fromArka={true}
      fatureDisabled={fatureDisabled}
      fleteName={"Banka"}
      fatureName={"Fature Blerje"}
      popupContextKeyType={true}
      popupContextKey="Banka"
      contexDelete={"banka"}
      onAddClick={onAddClick}
      onModifyClick={onModifyClick}
      // fetchLastNumberFature={fetchLastNumberFature}
      number={number}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchBanka={fetchBanka}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile
    ? ""
    : state.Veprimi === "FTSH"
    ? "Klient"
    : "Furnitor";
  const button7Text = isMobile ? "" : "Tjera";
  const button8Text = isMobile ? "" : "Check";

  const modalComponent = <MiniBanka klienti={klienti} />;

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      includeButton7={true}
      includeButton8={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<PeopleIcon />}
      button7Text={button7Text}
      button7Icon={<AutoAwesomeMotionIcon />}
      button8Text={button8Text}
      button8Icon={<AttachMoneyIcon />}
      isMobile={isMobile}
    />
  );

  const TabiKryesor = (
    <MainTabArka
      fleteHyrje={true}
      formatDate={formatDate}
      includeDateField={true}
      includeNrOrigjinesField={true}
      includeKursiField={false}
      includeMonedhaSelect={false}
      includeKodiField={true}
      includeModalList={true}
      disabled={disabled}
      state={state}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
    />
  );

  const smallTabsKM = (
    <div>
      {state.Veprimi === "FTSH" && (
        <TabKlienti
          fromArka={true}
          shenim={true}
          toggleState={toggleState === 1}
          disabled={disabled}
          state={state}
          handleChange={handleChange}
          setSearchString={setSearchString}
          searchString={searchString}
          handleKlientiType={handleKlientiType}
          qytetet={qytetet}
          shtetet={shtetet}
          klienti={klienti}
        />
      )}

      {state.Veprimi === "FTBL" && (
        <TabFurnitore
          qytetFurnitor={true}
          toggleState={toggleState === 1}
          disabled={disabled}
          state={state}
          handleChange={handleChange}
          setSearchString={setSearchString}
          searchString={searchString}
          handleFurnitorType={handleFurnitorType}
          qytetet={qytetet}
          furnitor={furnitori}
        />
      )}

      {/* tab4 */}
      <TabTjera
        fromArka={true}
        formatDate={formatDate}
        toggleState={toggleState === 2}
        disabled={disabled}
        state={state}
        updateDepKodi={updateDepKodi}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
      />

      <Check
        formatDate={formatDate}
        toggleState={toggleState === 3}
        disabled={disabled}
        state={state}
        updateDepKodi={updateDepKodi}
        handleChange={handleChange}
        includeButton6={false}
      />

      <TabKLS
        toggleState={toggleState === 4}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
      />
    </div>
  );

  return (
    <div
      className="mainDiv_ftsh"
      // style={{ overflow: isMobile ? "auto" : "visible" }}
    >
      <Toast ref={toast} />
      <NavBar currentAction={currentAction} />
      <div className={isMobile ? null : "template"}>
        <div className="main-container p-2">
          <div className="top_panel_fsh">
            <Panel
              headerTemplate={
                <Template
                  options={{
                    collapsed: isPanelToggleable,
                    onTogglerClick: () =>
                      setIsPanelToggleable(!isPanelToggleable),
                  }}
                  modalComponent={modalComponent}
                  fromArka={true}
                  pershkrimiArkes={pershkrimiArkes}
                  gjendjeArke={gjendjeArke}
                  isMobile={isMobile}
                  fatureName="Banka"
                  currentId={currentId}
                  nxtBtnDisable={nxtBtnDisable}
                  prvBtnDisable={prvBtnDisable}
                  state={state}
                  shikoFaturen={shikoFaturen}
                  handleTestiChange={handleTestiChange}
                  contextKey={"banka"}
                  visible={visible}
                  setVisible={setVisible}
                  isOnline={isOnline}
                  gridKey="Banka"
                />
              }
              toggleable
              collapsed={isPanelToggleable}
              onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
              style={{ height: "100%" }}
            >
              <div className="bg-white">
                {!isMobile ? (
                  <>
                    <div className="paneli-tabeve">
                      <div className="paneli-tabeve1">{PaneliKryesor}</div>
                      <div className="paneli-tabeve2">{PaneliDytesor}</div>
                      <div className="paneli-fis-print"></div>
                    </div>
                    <div className={"below-tabs"}>
                      <div className="below-tabs1">{TabiKryesor}</div>
                      <div className="below-tabs2">{smallTabsKM}</div>
                      <div className="below-tabs3"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 30px 10px",
                      }}
                    >
                      {PaneliKryesor}
                    </div>
                    <div style={{ margin: "10px 10px 30px 10px" }}>
                      {TabiKryesor}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 10px 20px 10px",
                      }}
                    >
                      {PaneliDytesor}
                    </div>
                    <div
                      style={{
                        margin: 10,
                      }}
                    >
                      {smallTabsKM}
                    </div>
                  </>
                )}
              </div>
            </Panel>
          </div>

          <div className={!isMobile ? "middle_fsh" : null}></div>

          <div className="dataTable_fsh">
            <PrimeGrid
              fromArka={true}
              deletedRowIds={deletedRowIds}
              setDeletedRowIds={setDeletedRowIds}
              gridKey="Banka"
              contextKey={"/banka/dtl"}
              isEditable={true}
              columns={columns}
              defaultColumnsStorage={defaultColumnsStorage}
              setColumns={setColumns}
              disabled={disabled}
              rows={rows}
              setRows={setRows}
              calculateData={handleCalculateData}
              shtoArtikull={handleShtoArtikull}
              currentAction={currentAction}
              currentId={currentId}
            />
          </div>
        </div>
        <ConfirmationDialog
          fleteName={"Banka"}
          fatureName={"Fature Blerje"}
          showConfirmation={showConfirmation}
          handleConfirmation={handleConfirmation}
          onClose={() => setShowConfirmation(false)}
        />
        {!isMobile ? <SideBar /> : null}
      </div>
      <Footer className="footer-position" />
    </div>
  );
};

export default memo(Banka);
